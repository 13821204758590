/* eslint-disable */
import {
	Geometry2d,
	RecordProps,
	Rectangle2d,
	ShapeUtil,
	SVGContainer,
	T,
	TLBaseShape,
	TLResizeInfo,
	resizeBox,
	TLDefaultColorStyle,
	DefaultColorStyle,
	getDefaultColorTheme,
} from "tldraw";
import { TLIconDefaultColor } from "@/helper";

type ICustomShape = TLBaseShape<
	"double_open_door",
	{
		w: number;
		h: number;
		color: TLDefaultColorStyle;
	}
>;

// [2]
export class DoubleOpenDoorElement extends ShapeUtil<ICustomShape> {
	static override type = "double_open_door" as const;
	static override props: RecordProps<ICustomShape> = {
		w: T.number,
		h: T.number,
		color: DefaultColorStyle as any,
	};

	// [b]
	getDefaultProps(): ICustomShape["props"] {
		return {
			w: 30,
			h: 16,
			color: TLIconDefaultColor,
		};
	}

	// [c]
	override canEdit() {
		return true;
	}
	override canResize() {
		return true;
	}
	override isAspectRatioLocked() {
		return false;
	}

	// [d]
	getGeometry(shape: ICustomShape): Geometry2d {
		return new Rectangle2d({
			width: shape.props.w,
			height: shape.props.h,
			isFilled: true,
		});
	}

	// [e]
	override onResize(shape: ICustomShape, info: TLResizeInfo<any>) {
		return resizeBox(shape, info);
	}

	// [f]
	component(shape: ICustomShape) {
		const theme = getDefaultColorTheme({ isDarkMode: false });
		const themeColor = theme[shape.props.color] as { solid: string };
		const fill = themeColor.solid;

		return (
			<SVGContainer>
				<svg
					fill="none"
					viewBox="13.271999999999998 5.491379310344827 29.456000000000003 15.35344827586207"
					xmlns="http://www.w3.org/2000/svg"
					// style="max-height: 500px"
					width={shape.props.w}
					height={shape.props.h}>
					<rect rx="2" height="26" width="56" />
					<mask fill={fill} id="path-2-inside-1_11634_7303">
						<path d="M28.0037 19.9998C28.0037 18.1608 27.6414 16.3399 26.9377 14.6408C26.2339 12.9418 25.2024 11.3981 23.9021 10.0977C22.6017 8.79733 21.0579 7.76582 19.3589 7.06207C17.6599 6.35831 15.8389 5.99609 13.9999 5.99609L13.9999 19.9998L28.0037 19.9998Z" />
					</mask>
					<path
						mask="url(#path-2-inside-1_11634_7303)"
						strokeWidth="0.56"
						stroke="#404040"
						fillOpacity="0.8"
						fill={fill}
						d="M28.0037 19.9998C28.0037 18.1608 27.6414 16.3399 26.9377 14.6408C26.2339 12.9418 25.2024 11.3981 23.9021 10.0977C22.6017 8.79733 21.0579 7.76582 19.3589 7.06207C17.6599 6.35831 15.8389 5.99609 13.9999 5.99609L13.9999 19.9998L28.0037 19.9998Z"
					/>
					<mask fill={fill} id="path-3-inside-2_11634_7303">
						<path d="M42.0001 5.99609C40.1611 5.99609 38.3401 6.35831 36.6411 7.06207C34.9421 7.76582 33.3983 8.79733 32.0979 10.0977C30.7976 11.3981 29.7661 12.9418 29.0623 14.6408C28.3586 16.3399 27.9963 18.1608 27.9963 19.9998L42.0001 19.9998L42.0001 5.99609Z" />
					</mask>
					<path
						mask="url(#path-3-inside-2_11634_7303)"
						strokeWidth="0.56"
						stroke="#404040"
						fillOpacity="0.8"
						fill={fill}
						d="M42.0001 5.99609C40.1611 5.99609 38.3401 6.35831 36.6411 7.06207C34.9421 7.76582 33.3983 8.79733 32.0979 10.0977C30.7976 11.3981 29.7661 12.9418 29.0623 14.6408C28.3586 16.3399 27.9963 18.1608 27.9963 19.9998L42.0001 19.9998L42.0001 5.99609Z"
					/>
				</svg>
			</SVGContainer>
		);
	}

	// [g]
	indicator() {
		return;
	}
}
