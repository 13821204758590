import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import CalculatorAnswer from "@/components/CalculatorAnswer";
import CalculatorWrapper from "@/components/CalculatorWrapper";
import { onboardingDetailsStorageType } from "@/types";
import { LOCAL_STORAGE_KEYS } from "@/utils/constant";
import type { InputData, ModelResult, budgetForm } from "@/data/types";

type formType = {
	form: budgetForm;
	data: InputData[];
};

type eventCeremoniesType = Array<{ label: string; value: string }> | [];

export default function ViewEventBudget() {
    const navigate = useNavigate();
    const location = useLocation();
    const containerRef = useRef<HTMLDivElement>(null);
    const [form, setForm] = useState<formType>();
    const [eventCeremonies, setEventCeremonies] = useState<eventCeremoniesType>([]);
    const [modelResult, setModelResult] = useState<ModelResult>();

    function updateDefaultOnboardingDetails(onboarding_details: onboardingDetailsStorageType) {
        if (onboarding_details) {
            const __ceremonies = onboarding_details?.ceremonies ? onboarding_details?.ceremonies : [];
            const ceremonies = __ceremonies as Array<{ label: string; value: string }> | [];
            setEventCeremonies(ceremonies);
        }
    }

    function getModelResult(modelResultDetails: ModelResult | null) {
        if (modelResultDetails) {
            setModelResult(modelResultDetails);
        }
    }

    function prefillCalculatorForm(calculatorDetails: formType | null) {
        if (calculatorDetails) {
            setForm(calculatorDetails);
        }
    }

    const defaultLink = "/onboarding/calculate-event-budget";

    const calculateBudgetURL = location.search.includes("mode=new") ? `${defaultLink}?mode=new` : defaultLink;

    useEffect(() => {
        const existingOnboardingDetails = window.localStorage.getItem(LOCAL_STORAGE_KEYS.onboarding_details);
        const existingModelDetails = window.localStorage.getItem(LOCAL_STORAGE_KEYS.model_result);
        const existingCalculatorDetails = window.localStorage.getItem(LOCAL_STORAGE_KEYS.calculator_details);
        const onboardingDetailsStorage = existingOnboardingDetails ? JSON.parse(existingOnboardingDetails) : null;
        const modelResultDetailsStorage = existingModelDetails ? JSON.parse(existingModelDetails) : null;
        const calculatorDetailsStorage = existingCalculatorDetails ? JSON.parse(existingCalculatorDetails) : null;

        updateDefaultOnboardingDetails(onboardingDetailsStorage);
        getModelResult(modelResultDetailsStorage);
        prefillCalculatorForm(calculatorDetailsStorage);

        if (!calculatorDetailsStorage && !modelResultDetailsStorage) {
            navigate(calculateBudgetURL);
        }
    }, []);

    function editFormDetails() {
        return navigate(calculateBudgetURL);
    }

    return (
        <CalculatorWrapper>
            <div className="container calculator_view" ref={containerRef}>
                {modelResult && form && (
                    <CalculatorAnswer
                        modelResult={modelResult}
                        form={form?.form}
                        data={form?.data}
                        onClick={editFormDetails}
                        eventCeremonies={eventCeremonies}
                    />
                )}
            </div>
        </CalculatorWrapper>
    );
}
