import { Dropdown } from "react-bootstrap";
import NotDoneIcon from "@/assets/icon/NotDoneIcon";
import OngoingIcon from "@/assets/icon/OngoingIcon";
import CompletedIcon from "@/assets/icon/CompletedIcon";

type planStage = "not_done" | "ongoing" | "done";

interface DisplayPlanningStage {
    stage: planStage;
}

function DisplayPlanningStage({ stage }: DisplayPlanningStage) {
    switch (stage) {
    case "ongoing":
        return (
            <>
                <OngoingIcon /> Ongoing
            </>
        );
    case "done":
        return (
            <>
                <CompletedIcon /> Done
            </>
        );
    case "not_done":
        return (
            <>
                <NotDoneIcon /> Not Done
            </>
        );
    }
}

interface Props {
    stage: planStage;
    updateStageHandler: (stage: planStage) => void;
    showLabel?: boolean;
}

export default function PlanningStageDropdown({ stage, updateStageHandler, showLabel = true }: Props) {
    return (
        <div className="stage_element">
            <Dropdown>
                {showLabel && <p className="label">Stage</p>}
                <Dropdown.Toggle variant="" id="dropdown-basic">
                    <DisplayPlanningStage stage={stage} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => updateStageHandler("not_done")}>
                        <NotDoneIcon /> Not Done
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => updateStageHandler("ongoing")}>
                        <OngoingIcon /> Ongoing
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => updateStageHandler("done")}>
                        <CompletedIcon /> Done
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}
