/* eslint-disable max-len */
import React from "react";

export default function NotDoneIcon({ stroke = "#EB4335" }) {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_10968_44974)">
                <path
                    d="M5.8916 1.27325C6.62369 1.13157 7.37617 1.13157 8.10826 1.27325M8.10826 12.7276C7.37617 12.8693 6.62369 12.8693 5.8916 12.7276M10.2718 2.171C10.891 2.59055 11.4238 3.12526 11.841 3.746M1.27276 8.10875C1.13108 7.37665 1.13108 6.62418 1.27276 5.89209M11.8293 10.2723C11.4098 10.8915 10.8751 11.4243 10.2543 11.8415M12.7271 5.89209C12.8688 6.62418 12.8688 7.37665 12.7271 8.10875M2.17051 3.7285C2.59006 3.10931 3.12477 2.57658 3.74551 2.15934M3.72801 11.8298C3.10882 11.4103 2.57609 10.8756 2.15885 10.2548"
                    stroke={stroke}
                    strokeWidth="1.16667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_10968_44974">
                    <rect width="14" height="14" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
