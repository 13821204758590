import ArmchairIcon from "@/assets/icon/ArmchairIcon";
import EntertainmentIcon from "@/assets/icon/EntertainmentIcon";
import StageIcon from "@/assets/icon/StageIcon";
import GiftIcon from "@/assets/icon/GiftIcon";
import UtensilsIcon from "@/assets/icon/Utensils";
import SofaIcon from "@/assets/icon/SofaIcon";
import TableOfTenIcon from "@/assets/icon/TableOfTenIcon";
import FloorplanCategoryTextIcon from "@/components/FloorplanElements/FloorplanCategoryTextIcon";
import DoorIcon from "@/assets/icon/DoorIcon";
import LayeredStageIcon from "@/assets/icon/LayeredStageIcon";
import GiftsIcon from "@/assets/icon/GiftsIcon";
import DoubleOpenDoorIcon from "@/assets/icon/DoubleOpenDoorIcon";
import DoubleClosedDoorIcon from "@/assets/icon/DoubleClosedDoorIcon";
import SingleOpenDoorIcon from "@/assets/icon/SingleOpenDoorIcon";
import SingleCloseDoorIcon from "@/assets/icon/SingleCloseDoorIcon";
import CircleIcon from "@/assets/icon/CircleIcon";
import SquareIcon from "@/assets/icon/SquareIcon";
import RectangleIcon from "@/assets/icon/RectangleIcon";
import TripleSofaIcon from "@/assets/icon/TripleSofaIcon";
import DoubleSofaIcon from "@/assets/icon/DoubleSofaIcon";
import Rectangle3Icon from "@/assets/icon/Rectangle3Icon";
import Rectangle6Icon from "@/assets/icon/Rectangle6Icon";
import Rectangle8Icon from "@/assets/icon/Rectangle8Icon";
import Rectangle10Icon from "@/assets/icon/Rectangle10Icon";
import TableOfEightIcon from "@/assets/icon/TableOfEightIcon";
import { FloorPlanObjectList } from "@/data/types";
import "@/styles/draw_floor_plan.scss";

export const fillSpaceElements = [
    {
        text: "Tables & Chairs",
        type: FloorPlanObjectList.table_chairs__category,
        icon: <ArmchairIcon />,
    },
    {
        text: "Entertainment",
        type: FloorPlanObjectList.entertainment__category,
        icon: <EntertainmentIcon />,
    },
    {
        text: "Food & Drinks",
        type: FloorPlanObjectList.food_drinks__category,
        icon: <UtensilsIcon />,
    },
    {
        text: "Stage",
        type: FloorPlanObjectList.stage__category,
        icon: <StageIcon />,
    },
    {
        text: "Doors",
        type: FloorPlanObjectList.doors__category,
        icon: <DoorIcon />,
    },
    {
        text: "Gifts",
        type: FloorPlanObjectList.gifts__category,
        icon: <GiftIcon />,
    },
];

export const floorPlanTableAndChairsElements = {
    chairs: {
        title: "Chairs",
        elements: [
            {
                text: "sofa",
                type: FloorPlanObjectList.sofa__table_chairs__floor_plan_element,
                icon: <FloorplanCategoryTextIcon icon={<TripleSofaIcon />} text="Sofa" />,
                url: "triple_sofa_element",
                element_id: "sofa",
            },
            {
                text: "single",
                type: FloorPlanObjectList.single_chair__table_chairs__floor_plan_element,
                icon: <FloorplanCategoryTextIcon icon={<SofaIcon />} text="Single" />,
                url: "single_sofa_element",
                element_id: "single_sofa",
            },
            {
                text: "double",
                type: FloorPlanObjectList.double_chair__table_chairs__floor_plan_element,
                icon: <FloorplanCategoryTextIcon icon={<DoubleSofaIcon />} text="Double" />,
                url: "double_sofa_element",
                element_id: "double_sofa",
            },
        ],
    },
    square_tables: {
        title: "Square Tables",
        elements: [
            {
                text: "square",
                type: FloorPlanObjectList.square__floor_plan_element,
                icon: <FloorplanCategoryTextIcon icon={<SquareIcon />} text="Square" />,
                id: "square_table",
                element_id: "square_table",
            },
        ],
    },
    round_tables: {
        title: "Round Tables",
        elements: [
            {
                text: "round table",
                type: FloorPlanObjectList.round_table__table_chairs__floor_plan_element,
                icon: <FloorplanCategoryTextIcon icon={<CircleIcon />} text="Round" />,
                id: "round_table",
                element_id: "round_table",
            },
            {
                text: "round table 10",
                type: FloorPlanObjectList.table_of_10__table_chairs__floor_plan_element,
                icon: <FloorplanCategoryTextIcon icon={<TableOfTenIcon />} text="Round" description="+10" />,
                url: "round_table_of_10",
                element_id: "round_table_10",
            },
            {
                text: "round table 8",
                type: FloorPlanObjectList.table_of_8__table_chairs__floor_plan_element,
                icon: <FloorplanCategoryTextIcon icon={<TableOfEightIcon />} text="Round" description="+8" />,
                element_id: "round_table_8",
                url: "round_table_of_8",
            },
        ],
    },
    rectangle_tables: {
        title: "Rectangle Tables",
        elements: [
            {
                text: "rectangle",
                type: FloorPlanObjectList.rectangle__floor_plan_element,
                icon: <FloorplanCategoryTextIcon icon={<RectangleIcon />} text="Rectangle" />,
                element_id: "rectangle_table",
                id: "rectangle_table",
            },
            {
                text: "rectangle table 3",
                type: FloorPlanObjectList.rectangle_3__floor_plan_element,
                icon: <FloorplanCategoryTextIcon icon={<Rectangle3Icon />} text="Rectangle" description="+3" />,
                url: "table_of_3",
                element_id: "rectangle_table_3",
            },
            {
                text: "rectangle table 6",
                type: FloorPlanObjectList.rectangle_6__floor_plan_element,
                icon: <FloorplanCategoryTextIcon icon={<Rectangle6Icon />} text="Rectangle" description="+6" />,
                url: "table_of_6",
                element_id: "rectangle_table_6",
            },
            {
                text: "rectangle table 8",
                type: FloorPlanObjectList.rectangle_8__floor_plan_element,
                icon: <FloorplanCategoryTextIcon icon={<Rectangle8Icon />} text="Rectangle" description="+8" />,
                element_id: "rectangle_table_8",
                url: "table_of_8",
            },
            {
                text: "rectangle table 10",
                type: FloorPlanObjectList.rectangle_10__floor_plan_element,
                icon: <FloorplanCategoryTextIcon icon={<Rectangle10Icon />} text="Rectangle" description="+10" />,
                url: "rectangle_table_10",
                element_id: "rectangle_table_10",
            },
        ],
    },
};

export const fillSpaceObjectElements = {
    table_chairs__category: [
        {
            text: "sofa",
            type: FloorPlanObjectList.sofa__table_chairs__floor_plan_element,
            icon: <FloorplanCategoryTextIcon icon={<TripleSofaIcon />} text="Sofa" />,
            url: "triple_sofa_element",
            element_id: "sofa",
        },
        {
            text: "single",
            type: FloorPlanObjectList.single_chair__table_chairs__floor_plan_element,
            icon: <FloorplanCategoryTextIcon icon={<SofaIcon />} text="Single" />,
            url: "single_sofa_element",
            element_id: "single_sofa",
        },
        {
            text: "double",
            type: FloorPlanObjectList.double_chair__table_chairs__floor_plan_element,
            icon: <FloorplanCategoryTextIcon icon={<DoubleSofaIcon />} text="Double" />,
            url: "double_sofa_element",
            element_id: "double_sofa",
        },
        {
            text: "square",
            type: FloorPlanObjectList.square__floor_plan_element,
            icon: <FloorplanCategoryTextIcon icon={<SquareIcon />} text="Square" />,
            id: "square_table",
            element_id: "square_table",
        },
        {
            text: "round table",
            type: FloorPlanObjectList.round_table__table_chairs__floor_plan_element,
            icon: <FloorplanCategoryTextIcon icon={<CircleIcon />} text="Round" />,
            id: "round_table",
            element_id: "round_table",
        },
        {
            text: "round table 10",
            type: FloorPlanObjectList.table_of_10__table_chairs__floor_plan_element,
            icon: <FloorplanCategoryTextIcon icon={<TableOfTenIcon />} text="Round" description="+10" />,
            url: "https://storage.googleapis.com/plan-a-day-12b4c.appspot.com/floor-plan/chair_10__icon.svg",
            element_id: "round_table_10",
        },
        {
            text: "round table 8",
            type: FloorPlanObjectList.table_of_8__table_chairs__floor_plan_element,
            icon: <FloorplanCategoryTextIcon icon={<TableOfEightIcon />} text="Round" description="+8" />,
            url: "https://storage.googleapis.com/plan-a-day-12b4c.appspot.com/floor-plan/chair_8__icon.svg",
            element_id: "round_table_8",
        },
        {
            text: "rectangle",
            type: FloorPlanObjectList.rectangle__floor_plan_element,
            icon: <FloorplanCategoryTextIcon icon={<RectangleIcon />} text="Rectangle" />,
            id: "rectangle_table",
            element_id: "rectangle_table",
        },
        {
            text: "rectangle table 3",
            type: FloorPlanObjectList.rectangle_3__floor_plan_element,
            icon: <FloorplanCategoryTextIcon icon={<Rectangle3Icon />} text="Rectangle" description="+3" />,
            url: "https://storage.googleapis.com/plan-a-day-12b4c.appspot.com/floor-plan/rectangle_3__icon.svg",
            element_id: "rectangle_table_3",
        },
        {
            text: "rectangle table 6",
            type: FloorPlanObjectList.rectangle_6__floor_plan_element,
            icon: <FloorplanCategoryTextIcon icon={<Rectangle6Icon />} text="Rectangle" description="+6" />,
            url: "https://storage.googleapis.com/plan-a-day-12b4c.appspot.com/floor-plan/rectangle_6__icon.svg",
            element_id: "rectangle_table_6",
        },
        {
            text: "rectangle table 8",
            type: FloorPlanObjectList.rectangle_8__floor_plan_element,
            icon: <FloorplanCategoryTextIcon icon={<Rectangle8Icon />} text="Rectangle" description="+8" />,
            url: "https://storage.googleapis.com/plan-a-day-12b4c.appspot.com/floor-plan/rectangle_8__icon.svg",
            element_id: "rectangle_table_8",
        },
        {
            text: "rectangle table 10",
            type: FloorPlanObjectList.rectangle_10__floor_plan_element,
            icon: <FloorplanCategoryTextIcon icon={<Rectangle10Icon />} text="Rectangle" description="+10" />,
            url: "https://storage.googleapis.com/plan-a-day-12b4c.appspot.com/floor-plan/rectangle_10__icon.svg",
            element_id: "rectangle_table_10",
        },
    ],
    entertainment__category: [
        {
            type: FloorPlanObjectList.element_with_text,
            text: "Band",
            icon: <FloorplanCategoryTextIcon title="Band" />,
            url: "band_element",
            element_id: "band_element",
        },
        {
            type: FloorPlanObjectList.element_with_text,
            text: "DJ",
            icon: <FloorplanCategoryTextIcon title="DJ" />,
            url: "dj_element",
            element_id: "dj_element",
        },
        {
            type: FloorPlanObjectList.element_with_text,
            text: "Sound",
            icon: <FloorplanCategoryTextIcon title="Sound" description="Equipment" />,
            url: "sound_element",
            element_id: "sound_element",
        },
        {
            type: FloorPlanObjectList.element_with_text,
            text: "Dance Floor",
            icon: <FloorplanCategoryTextIcon title="Dance Floor" />,
            url: "dance_floor_element",
            element_id: "dance_element",
        },
    ],
    food_drinks__category: [
        {
            type: FloorPlanObjectList.element_with_text,
            text: "Buffet",
            icon: <FloorplanCategoryTextIcon title="Buffet" />,
            url: "buffet_element",
            element_id: "buffet_element",
        },
        {
            type: FloorPlanObjectList.element_with_text,
            text: "Cake Table",
            icon: <FloorplanCategoryTextIcon title="Cake" text="Cake Table" />,
            url: "cake_table_element",
            element_id: "cake_element",
        },
        {
            type: FloorPlanObjectList.element_with_text,
            text: "Cocktails",
            icon: <FloorplanCategoryTextIcon title="Cocktails" />,
            url: "cocktail_element",
            element_id: "cocktail_element",
        },
        {
            type: FloorPlanObjectList.element_with_text,
            text: "Drinks Bar",
            icon: <FloorplanCategoryTextIcon title="Drinks" text="Drinks Bar" />,
            url: "drinks_element",
            element_id: "drinks_element",
        },
        {
            type: FloorPlanObjectList.element_with_text,
            text: "Dessert Table",
            icon: <FloorplanCategoryTextIcon title="Dessert" description="Table" />,
            url: "dessert_element",
            element_id: "dessert_element",
        },
        {
            type: FloorPlanObjectList.element_with_text,
            text: "Catering Station",
            icon: <FloorplanCategoryTextIcon title="Catering" description="Station" />,
            url: "catering_station",
            element_id: "catering_station",
        },
    ],
    stage__category: [
        {
            text: "Stage",
            type: FloorPlanObjectList.stage_stairs__floor_plan_element,
            icon: <FloorplanCategoryTextIcon icon={<LayeredStageIcon />} text="Stage" description="+stairs" />,
            url: "stage_element",
            element_id: "stage_element",
        },
        {
            text: "Stage stairs",
            type: FloorPlanObjectList.stage__floor_plan_element,
            icon: <FloorplanCategoryTextIcon title="Stage" description="-stairs" />,
            url: "stage_stairs",
            element_id: "stage_stairs_element",
        },
    ],
    doors__category: [
        {
            text: "Double open door",
            type: FloorPlanObjectList.double_door_open__floor_plan_element,
            icon: <FloorplanCategoryTextIcon icon={<DoubleOpenDoorIcon />} text="Double" description="Open" />,
            url: "double_open_door",
            element_id: "double_open_door",
        },
        {
            text: "Double closed door",
            type: FloorPlanObjectList.double_door_closed__floor_plan_element,
            icon: <FloorplanCategoryTextIcon icon={<DoubleClosedDoorIcon />} text="Double" description="Closed" />,
            url: "double_closed_door",
            element_id: "double_closed_door",
        },
        {
            text: "Single open door",
            type: FloorPlanObjectList.single_door_open__floor_plan_element,
            icon: <FloorplanCategoryTextIcon icon={<SingleOpenDoorIcon />} text="Single" description="Open" />,
            url: "single_open_door",
            element_id: "single_open_door",
        },
        {
            text: "Single closed door",
            type: FloorPlanObjectList.single_door_closed__floor_plan_element,
            icon: <FloorplanCategoryTextIcon icon={<SingleCloseDoorIcon />} text="Single" description="Closed" />,
            url: "single_closed_door",
            element_id: "single_closed_door",
        },
    ],
    gifts__category: [
        {
            text: "Gift",
            type: FloorPlanObjectList.gifts__floor_plan_element,
            icon: <FloorplanCategoryTextIcon icon={<GiftsIcon />} text="Gifts" />,
            url: "gift_element",
            element_id: "gift_element",
        },
    ],
};
