import cake from "../assets/img/cake.png";
import flowers from "../assets/img/flowers.png";
import mrmrs from "../assets/img/mrmrs.png";
import facebook from "../assets/img/facebook.svg";
import twitter from "../assets/img/twitter.svg";
import whatsapp from "../assets/img/whatsapp.svg";

export const links = [
    {
        text: "Recently engaged? Not sure what’s next...",
        subtext: "Get started with our top planning tips for every Nigerian bride",
        link: "https://www.blog.planaday.events/how-to-plan-a-nigerian-wedding-tips-for-every-bride/",
        icon: cake,
    },
    {
        text: "Looking for the perfect wedding venue?",
        subtext: "Ask the right questions using our printable venue checklist",
        link: "https://www.blog.planaday.events/ultimate-venue-viewing-checklist-for-your-nigerian-wedding/",
        icon: flowers,
    },
    {
        text: "Stress-free wedding ...yes, please!",
        subtext: "Here are a few things to consider for more fun, less stress",
        link: "https://www.blog.planaday.events/tips-to-reduce-stress-on-your-wedding-day/",
        icon: mrmrs,
    },
];

const shareMessage = "I just created my wedding budget on Planaday. Create yours too!";
const url = `${process.env.REACT_APP_PLANADAY_BASE_URL}/budget`;
const hashtag = "%23planadayweddings";

export const socials = [
    {
        name: "facebook",
        icon: facebook,
        link: `https://www.facebook.com/sharer.php?u=${url}&quote=${shareMessage} ${hashtag}`,
    },
    {
        name: "twitter",
        icon: twitter,
        link: `https://twitter.com/intent/tweet?url=${url} ${hashtag}&text=${shareMessage}`,
    },
    // {
    //   icon: instagram,
    //   link:  `https://www.instagram.com/p/CconFK0pOmS/?utm_source=${url};utm_campaign=loading`,
    // },
    {
        name: "whatsapp",
        icon: whatsapp,
        link: `https://wa.me/?text=${shareMessage} ${url}  ${hashtag}`,
    },
];

export const categoryLabels = {
    planning_and_decoration: {
        title: "Planning & Decoration",
        description: "",
    },
    food_drink: { title: "Food & Drinks", description: "" },
    wedding_apparel: { title: "Wedding Apparel", description: "" },
    entertainment_and_media: { title: "Entertainment & Media", description: "" },
    security_and_staffing: { title: "Security & Staffing", description: "" },
    stationaries: { title: "Stationaries", description: "" },
    rentals: { title: "Rentals", description: "" },
    miscellaneous: {
        title: "Miscellaneous",
        description: "Surprises happen! We've estimated this amount to keep you covered",
    },
};
