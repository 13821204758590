/* eslint-disable */
import {
	Geometry2d,
	RecordProps,
	Rectangle2d,
	ShapeUtil,
	SVGContainer,
	T,
	TLBaseShape,
	TLResizeInfo,
	resizeBox,
	TLDefaultColorStyle,
	DefaultColorStyle,
	getDefaultColorTheme,
} from "tldraw";
import { TLIconDefaultColor } from "@/helper";

type ICustomShape = TLBaseShape<
	"round_table_8",
	{
		w: number;
		h: number;
		color: TLDefaultColorStyle;
	}
>;

// [2]
export class RoundTable8Element extends ShapeUtil<ICustomShape> {
	static override type = "round_table_8" as const;
	static override props: RecordProps<ICustomShape> = {
		w: T.number,
		h: T.number,
		color: DefaultColorStyle as any,
	};

	// [b]
	getDefaultProps(): ICustomShape["props"] {
		return {
			w: 14,
			h: 14,
			color: TLIconDefaultColor,
		};
	}

	// [c]
	override canEdit() {
		return true;
	}
	override canResize() {
		return true;
	}
	override isAspectRatioLocked() {
		return false;
	}

	// [d]
	getGeometry(shape: ICustomShape): Geometry2d {
		return new Rectangle2d({
			width: shape.props.w,
			height: shape.props.h,
			isFilled: true,
		});
	}

	// [e]
	override onResize(shape: ICustomShape, info: TLResizeInfo<any>) {
		return resizeBox(shape, info);
	}

	// [f]
	component(shape: ICustomShape) {
		const theme = getDefaultColorTheme({ isDarkMode: false });
		const themeColor = theme[shape.props.color] as { solid: string };
		const fill = themeColor.solid;

		return (
			<SVGContainer>
				<svg width={shape.props.w} height={shape.props.h} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M6.99993 4.04286C8.63308 4.04286 9.95701 5.36685 9.95701 7C9.95701 8.63315 8.63308 9.95714 6.99993 9.95714C5.36679 9.95714 4.04286 8.63315 4.04286 7C4.04286 5.36685 5.36679 4.04286 6.99993 4.04286Z"
						fill={fill}
						stroke="#404040"
						strokeWidth="0.0857133"
					/>
					<path
						d="M6.40001 2L7.59999 2C7.82091 2 8 1.82091 8 1.59999L8 0.399934C8 0.179017 7.82091 -7.27973e-05 7.59999 -7.27876e-05L6.40001 -7.27352e-05C6.17909 -7.27255e-05 6 0.179016 6 0.399934L6 1.59999C6 1.82091 6.17909 2 6.40001 2Z"
						fill={fill}
					/>
					<path
						d="M6.40001 14L7.59999 14C7.82091 14 8 13.8209 8 13.6L8 12.3999C8 12.179 7.82091 11.9999 7.59999 11.9999L6.40001 11.9999C6.17909 11.9999 6 12.179 6 12.3999L6 13.6C6 13.8209 6.17909 14 6.40001 14Z"
						fill={fill}
					/>
					<path
						d="M10.9603 10.1115L10.1118 10.96C9.95555 11.1162 9.95554 11.3695 10.1118 11.5257L10.9603 12.3743C11.1165 12.5305 11.3697 12.5305 11.5259 12.3743L12.3745 11.5257C12.5307 11.3695 12.5307 11.1162 12.3745 10.96L11.526 10.1115C11.3698 9.95525 11.1165 9.95525 10.9603 10.1115Z"
						fill={fill}
					/>
					<path
						d="M2.47496 1.62562L1.62641 2.47418C1.4702 2.63039 1.47019 2.88366 1.6264 3.03988L2.4749 3.88844C2.63111 4.04466 2.88438 4.04466 3.04059 3.88845L3.88915 3.03989C4.04536 2.88368 4.04536 2.63041 3.88916 2.47419L3.04065 1.62563C2.88444 1.46941 2.63118 1.46941 2.47496 1.62562Z"
						fill={fill}
					/>
					<path
						d="M12 6.39952V7.59958C12 7.8205 12.1791 7.99958 12.4 7.99958H13.6C13.8209 7.99958 14 7.8205 14 7.59958V6.39952C14 6.1786 13.8209 5.99951 13.6 5.99951H12.4C12.1791 5.99951 12 6.1786 12 6.39952Z"
						fill={fill}
					/>
					<path
						d="M0 6.39952V7.59958C0 7.8205 0.179089 7.99958 0.400007 7.99958H1.59999C1.82091 7.99958 2 7.8205 2 7.59958V6.39952C2 6.1786 1.82091 5.99951 1.59999 5.99951H0.400007C0.179089 5.99951 0 6.1786 0 6.39952Z"
						fill={fill}
					/>
					<path
						d="M10.111 3.03968L10.9595 3.88821C11.1157 4.04443 11.369 4.04442 11.5252 3.88821L12.3737 3.03962C12.5299 2.8834 12.5299 2.63013 12.3737 2.47392L11.5252 1.62539C11.369 1.46917 11.1157 1.46918 10.9595 1.6254L10.111 2.47398C9.95476 2.6302 9.95476 2.88347 10.111 3.03968Z"
						fill={fill}
					/>
					<path
						d="M1.62562 11.5255L2.47415 12.3741C2.63036 12.5303 2.88363 12.5303 3.03984 12.374L3.88837 11.5255C4.04458 11.3692 4.04457 11.116 3.88836 10.9598L3.03983 10.1112C2.88362 9.95501 2.63035 9.95502 2.47414 10.1112L1.62561 10.9598C1.4694 11.116 1.46941 11.3693 1.62562 11.5255Z"
						fill={fill}
					/>
				</svg>
			</SVGContainer>
		);
	}

	// [g]
	indicator() {
		return;
	}
}
