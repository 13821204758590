import axios from "axios";

import { RequestErrorType } from "@/types";
import type { AxiosResponseNoDataType } from "@/data/types";

const BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

export async function deleteCostItem(id: string, categoryId: string, eventId: string): Promise<AxiosResponseNoDataType> {
    try {
        const data = await axios.delete(`${BASE_URL}/api/v1/event/${eventId}/category/${categoryId}/cost-item/${id}`);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function deleteBudgetCategory(categoryId: string, eventId: string): Promise<AxiosResponseNoDataType> {
    try {
        const data = await axios.delete(`${BASE_URL}/api/v1/event/${eventId}/category/${categoryId}`);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function deleteChecklist(ceremonyId: string, checklistId: string, eventId: string): Promise<AxiosResponseNoDataType> {
    try {
        const data = await axios.delete(`${BASE_URL}/api/v1/event/${eventId}/checklist/${ceremonyId}/${checklistId}`);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function deleteEventRequest(eventId: string): Promise<AxiosResponseNoDataType> {
    try {
        const data = await axios.delete(`${BASE_URL}/api/v1/event/${eventId}`);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error?.response?.data?.message);
    }
}

export async function deleteCeremonyRequest(ceremonyId: string, eventId: string): Promise<AxiosResponseNoDataType> {
    try {
        const data = await axios.delete(`${BASE_URL}/api/v1/event/${eventId}/ceremony/${ceremonyId}`);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function deleteVendorRequest(vendorId: string): Promise<AxiosResponseNoDataType> {
    try {
        const data = await axios.delete(`${BASE_URL}/api/v1/event-vendor/${vendorId}`);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function deleteFloorplanRequest(floorplanId: string, eventId: string): Promise<AxiosResponseNoDataType> {
    try {
        const data = await axios.delete(`${BASE_URL}/api/v1/${eventId}/floorplan/${floorplanId}`);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function deleteTestUserDetails(): Promise<AxiosResponseNoDataType> {
    try {
        const data = await axios.delete(`${BASE_URL}/api/v1/test-cleanup-controller`);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function deleteEventDayTaskRequest(
    eventDayFlowId: string,
    eventId: string,
    ceremonyId: string,
) {
    try {
        const data = await axios.delete(`${BASE_URL}/api/v1/event/${eventId}/ceremony/${ceremonyId}/event-day-flow/${eventDayFlowId}`);
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function deletePlannningTimelineRequest(planningTimelineId: string, eventId: string, ceremonyId: string) {
    try {
        const data = await axios.delete(`${BASE_URL}/api/v1/event/${eventId}/ceremony/${ceremonyId}/planning-timeline/${planningTimelineId}`);
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}
