import { ChangeEvent, Suspense, useCallback, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { formatDistanceStrict } from "date-fns";

import useUser from "@/hooks/useUser";
import useMyEvents from "@/hooks/useMyEvents";
import PlusIcon from "@/assets/icon/PlusIcon";
import ThreedotIcon from "@/assets/icon/ThreedotIcon";
import NewDashboardLayout from "@/layout/NewDashboardLayout";
import TipsAndIdeas from "@/components/TipsAndIdeas";
import ManageChecklist from "@/components/ManageChecklist";
import ManageBudgetlist from "@/components/ManageBudgetlist";
import { costItemType, UserList } from "@/types";
import { eventImage } from "@/utils/event_card";
import { updateEventPictureRequest, updateTourGuide } from "@/axios/post-request";
import SiteDropdown from "@/components/Dropdown";
import CodeIcon from "@/assets/icon/CodeIcon";
import { useEvent } from "@/hooks/useEvent";
import ForecastCeremonyWeather from "@/components/ForecastCeremonyWeather";
import DraggableCeremonyChecklist from "@/components/DraggableCeremonyChecklist";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import PencilIcon from "@/assets/icon/PencilIcon";
import FilterDropdown from "@/components/FilterDropdown";
import EditEventCeremonyModal from "@/components/modals/EditEventCeremonyModal";
import EditEventModal from "@/components/modals/EditEventModal";
import DeleteCeremonyModal from "@/components/modals/DeleteCeremonyModal";
import CreateNewCeremonyModal from "@/components/modals/CreateNewCeremonyModal";
import OverviewWelcomeModal from "@/components/modals/OverviewWelcomeModal";
import { isDateWithin14daysRange } from "@/helper";
import { CollaboratorPermissionList } from "@/data/types";
import "@/styles/dashboard_home.scss";

type TodoType = Array<{
    isDone: boolean;
    due: Date;
    id: string;
    name: string;
    note: string | null;
    priority?: string;
    ceremony: { name: string; id: string };
}>;

enum ModalList {
    edit_ceremony_modal = "edit_ceremony_modal",
    edit_event_modal = "edit_event_modal",
    delete_ceremony_modal = "delete_ceremony_modal",
    welcome_modal = "welcome_modal",
    overview_welcome_modal = "overview_welcome_modal",
    create_new_ceremony_modal = "create_new_ceremony_modal",
}
type ModalType = keyof typeof ModalList;

export default function EventOverviewPage() {
    const { data, activeEvent, refetch, status } = useMyEvents();
    const { activeCeremonyId, selectedEventId, setSelectedEventId, setActiveCeremonyId } = useEvent();
    const { data: userData } = useUser();
    const [modal, setModal] = useState<ModalType | null>(null);
    const mobileDevice = useMediaQuery("(max-width: 768px)");
    const selectedEvent = activeEvent ? activeEvent[0] : null;
    const [previewEventImage, setPreviewEventImage] = useState<null | string>(null);

    const eventData = data?.data?.status ? data?.data : null;
    const userDetails = userData?.result ? userData?.result : null;

    const upcomingPayments =
        selectedEvent && selectedEvent?.budget && selectedEvent?.budget?.categories
            ? selectedEvent?.budget?.categories?.reduce((acc: costItemType[], category) => {
                const validCostItems = category.cost_items.filter((item) => item.balance_due !== null && item.balance > 0);
                return acc.concat(validCostItems);
            }, [])
            : null;

    const __ceremonyUpcomingPayment =
        upcomingPayments && upcomingPayments?.length > 0
            ? upcomingPayments.filter((item) => item.tag === activeCeremonyId && item.balance_due && !isDateWithin14daysRange(item.balance_due))
            : [];
    const ceremonyUpcomingPayment = __ceremonyUpcomingPayment.length > 0 ? __ceremonyUpcomingPayment : __ceremonyUpcomingPayment;

    useEffect(() => {
        if (userData?.result && !userData?.result?.tour_guide?.event_overview && userData.result.user_type === UserList.celebrant) {
            setModal(ModalList.overview_welcome_modal);
        }
    }, [userData]);

    const isCeremonyAvailable = selectedEvent && selectedEvent?.ceremonies ? (selectedEvent?.ceremonies?.length > 0 ? true : false) : false;

    function onCloseModal() {
        setModal(null);
    }

    function selectCeremonyHandler(ceremonyId: string) {
        setActiveCeremonyId(ceremonyId);
    }

    const activeCeremony =
        selectedEvent && selectedEvent?.ceremonies && activeCeremonyId
            ? selectedEvent.ceremonies.filter((ceremony) => ceremony.id === activeCeremonyId)[0]
            : null;
    const eventImg =
        activeCeremony?.image || selectedEvent?.event_image
            ? activeCeremony?.image
                ? activeCeremony?.image
                : selectedEvent?.event_image
            : eventImage(selectedEvent?.event_name as string);

    function onSelectEventHandler(eventId: string) {
        const selectedEvent = eventData?.result ? eventData?.result?.filter((item) => item.id === eventId)[0] : null;
        if (selectedEvent) {
            const selectedEventCeremonyId = selectedEvent.ceremonies[0].id;
            setSelectedEventId(eventId);
            setActiveCeremonyId(selectedEventCeremonyId);
        }
    }

    const today = new Date();
    const eventName = selectedEvent ? selectedEvent?.event_name : "Pick an event";
    const ceremonyText = activeCeremony ? activeCeremony?.name : "Ceremony";
    const dateCountDown =
        activeCeremony && activeCeremonyId && activeCeremony.date
            ? formatDistanceStrict(new Date(activeCeremony.date), new Date(), { addSuffix: false })
            : "";

    const eventText = activeCeremony?.date ? (new Date(activeCeremony.date) > today ? "till event" : "after event") : "";

    const isUserEventAvailable = eventData?.result && eventData?.result.length > 0 ? true : false;
    const todo = activeCeremony?.checklist
        ? activeCeremony?.checklist?.filter((item) => !item.isDone && item.due && new Date(item.due) >= today && !isDateWithin14daysRange(item.due))
        : [];
    const todoArray = todo as TodoType;
    const sortTodo = todoArray.length > 1 ? todoArray.sort((a, b) => +new Date(b.due) - +new Date(a.due)) : todoArray;
    const firstFiveTodoList = sortTodo.length > 5 ? sortTodo?.slice(0, 5) : sortTodo;

    function refetchQueryHandler() {
        return refetch();
    }

    const hasUserSubscribed = !!userData?.result?.subscription_details?.id;

    async function changePhotoHandler(event: ChangeEvent<HTMLInputElement>) {
        try {
            event.preventDefault();
            const target = event.target as HTMLInputElement;
            const file: File = (target.files as FileList)[0];
            const url = URL.createObjectURL(file);
            setPreviewEventImage(url);

            if (file) {
                const eventPictureFormData = new FormData();
                eventPictureFormData.append("file", file);
                await updateEventPictureRequest(eventPictureFormData, selectedEventId);
                setPreviewEventImage("");
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    function createCeremonyHandler() {
        if (!eventData) {
            return toast.error("Please create an event");
        }
        if (!hasUserSubscribed) {
            return toast.error("Subscription is required");
        }
        setModal(ModalList.create_new_ceremony_modal);
    }

    function editCeremonyHandler() {
        if (!eventData) {
            return toast.error("Please create an event");
        }
        setModal(ModalList.edit_ceremony_modal);
    }

    function editEventHandler() {
        if (!eventData) {
            return toast.error("Please create an event");
        }
        setModal(ModalList.edit_event_modal);
    }

    function deleteCeremonyHandler() {
        if (!eventData) {
            return toast.error("Please create an event");
        }
        setModal(ModalList.delete_ceremony_modal);
    }

    async function onCloseOverviewWelcomeModal() {
        setModal(null);
        await updateTourGuide({
            event_overview: true,
        });
    }

    const numberOfCollaborators = selectedEvent ? (selectedEvent?.collaborators ? selectedEvent?.collaborators.length : 0) : 0;

    const renderChecklist = useCallback(() => {
        return (
            <ManageChecklist checklist={firstFiveTodoList}>
                {firstFiveTodoList?.length > 0 && (
                    <DraggableCeremonyChecklist
                        hasUserSubscribed={hasUserSubscribed}
                        refetchQueryHandler={refetchQueryHandler}
                        todoList={firstFiveTodoList}
                    />
                )}
            </ManageChecklist>
        );
    }, [selectedEventId, firstFiveTodoList.length, activeCeremonyId]);

    const disableButton = status === "loading";

    return (
        <>
            <Suspense>
                {modal === ModalList.overview_welcome_modal && <OverviewWelcomeModal show={true} onClose={onCloseOverviewWelcomeModal} />}
                {modal === ModalList.create_new_ceremony_modal && (
                    <CreateNewCeremonyModal isUserEventAvailable={isUserEventAvailable} onClose={onCloseModal} />
                )}
                {modal === ModalList.edit_ceremony_modal && activeCeremony && (
                    <EditEventCeremonyModal activeCeremony={activeCeremony} onClose={onCloseModal} />
                )}
                {modal === ModalList.edit_event_modal && <EditEventModal event={selectedEvent} onClose={onCloseModal} />}
                {modal === ModalList.delete_ceremony_modal && (
                    <DeleteCeremonyModal
                        ceremonyName={activeCeremony?.name as string}
                        activeEventId={selectedEvent?.id as string}
                        selectedCeremonyId={activeCeremonyId}
                        onClose={onCloseModal}
                    />
                )}
            </Suspense>
            <NewDashboardLayout title="Overview" description="Bird's eye view of your event's details">
                <section className="homepage">
                    <div className="event_top_view">
                        <div className="event_overview_top_bar">
                            <div className="row_1">
                                <SiteDropdown
                                    className="custom pick_event_dropdown"
                                    title={
                                        <div className="pick_an_event">
                                            <div className="text">{eventName}</div>
                                            <CodeIcon />
                                        </div>
                                    }>
                                    {eventData?.result && Array.isArray(eventData?.result) && eventData?.result?.length > 0 ? (
                                        eventData?.result?.map((eventItem, index) => (
                                            <Dropdown.Item key={index} onClick={() => onSelectEventHandler(eventItem.id)}>
                                                {eventItem.event_name}
                                            </Dropdown.Item>
                                        ))
                                    ) : (
                                        <p>No available event, please create a new event</p>
                                    )}
                                </SiteDropdown>
                                {mobileDevice && (
                                    <Link to="/dashboard/collaborators" className="collaborators">
                                        Collaborators <span>{numberOfCollaborators}</span>
                                    </Link>
                                )}
                            </div>
                            <div className="controls">
                                <FilterDropdown title={ceremonyText} disabled={!isCeremonyAvailable}>
                                    {selectedEvent?.ceremonies &&
                                        selectedEvent?.ceremonies?.map((ceremony, index) => (
                                            <Dropdown.Item key={index} onClick={() => selectCeremonyHandler(ceremony.id)}>
                                                {ceremony.name}
                                            </Dropdown.Item>
                                        ))}
                                </FilterDropdown>
                                {!mobileDevice && (
                                    <Link to="/dashboard/collaborators" className="collaborators">
                                        Collaborators <span>{numberOfCollaborators}</span>
                                    </Link>
                                )}
                                <div className="control_group">
                                    {userDetails?.permission !== CollaboratorPermissionList.view_only && (
                                        <button className="create_ceremony" disabled={disableButton} onClick={createCeremonyHandler}>
                                            <span>Create Ceremony</span>
                                            <PlusIcon fill="#fff" />
                                        </button>
                                    )}
                                    {hasUserSubscribed && (
                                        <SiteDropdown className="custom three_dots" title={<ThreedotIcon />}>
                                            <Dropdown.Item onClick={editCeremonyHandler}>Edit ceremony</Dropdown.Item>
                                            <Dropdown.Item onClick={editEventHandler}>Edit event</Dropdown.Item>
                                            <Dropdown.Item onClick={deleteCeremonyHandler}>Delete ceremony</Dropdown.Item>
                                        </SiteDropdown>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="info_cards">
                            <div className="card_group">
                                <div className="card first">
                                    <div className="row">
                                        <h4>{dateCountDown ? dateCountDown : "- -"}</h4>
                                        <p className="icon">⏳</p>
                                    </div>
                                    <p>{!dateCountDown ? "Days till event" : eventText}</p>
                                </div>
                                <div className="card second">
                                    <div className="row">
                                        <h4>{activeCeremony?.no_of_guests?.toLocaleString() ?? "- -"}</h4>
                                        <p className="icon">🎎</p>
                                    </div>
                                    <p>Guests</p>
                                </div>
                                <div className="card third">
                                    <div className="row">
                                        <div className="d-flex event_location align-items-center">
                                            <h4>{activeCeremony?.location ?? "- -"}</h4>
                                            {activeCeremony && <ForecastCeremonyWeather currentCeremony={activeCeremony} />}
                                        </div>
                                        <p className="icon">📍</p>
                                    </div>
                                    <p>Location</p>
                                </div>
                                {mobileDevice && (
                                    <div className="card_img">
                                        <img src={eventImg} alt="event banner" />
                                    </div>
                                )}
                            </div>
                            {!mobileDevice && (
                                <div className="card_img">
                                    {selectedEventId && hasUserSubscribed && (
                                        <div className="add_image">
                                            <PencilIcon />
                                            <input onChange={changePhotoHandler} type="file" accept="image/png, image/webp, image/jpg, image/jpeg" />
                                        </div>
                                    )}
                                    <img src={previewEventImage ? previewEventImage : eventImg} alt="event banner" />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="grid_view">
                        <ManageBudgetlist upcomingPayments={ceremonyUpcomingPayment} ceremonyDate={activeCeremony?.date} />
                        {renderChecklist()}
                    </div>
                    <TipsAndIdeas />
                </section>
            </NewDashboardLayout>
        </>
    );
}
