import { v4 as uuidv4 } from "uuid";
import { ChangeEvent, CSSProperties, memo, useCallback, useEffect, useRef, useState } from "react";
import { Editor, TLShapeId, Tldraw, exportToBlob, getSnapshot } from "tldraw";
import FuzzySearch from "fuzzy-search";
import { Dropdown } from "react-bootstrap";
import { jsPDF } from "jspdf";
import { ExpandIcon, PlusIcon, ShrinkIcon } from "lucide-react";
import "tldraw/tldraw.css";

import logo from "@/assets/img/logo_full.png";
import { fillSpaceElements, fillSpaceObjectElements } from "@/utils/draw_floor_plan_icons";
import { FloorPlanObjectListType } from "@/data/types";
import useFloorPlan from "@/hooks/useFloorPlan";
import Spinner from "@/components/Spinner";
import Search from "@/assets/icon/Search";
import SquareDashedIcon from "@/assets/icon/SquareDashedIcon";
import { createFloorPlanRequest } from "@/axios/post-request";
import { useEvent } from "@/hooks/useEvent";
import SiteDropdown from "@/components/Dropdown";
import ThreeDotsIcon from "@/assets/icon/ThreeDotsIcon";
import useToast from "@/hooks/useToast";
import { deleteFloorplanRequest } from "@/axios/delete-request";
import useClickOutside from "@/hooks/useClickOutside";
import SaveIcon from "@/assets/icon/SaveIcon";
import FloorPlanElement from "./FloorPlanElement";
import FloorplanCustomShapes from "./FloorplanCustomShape";
import { updateFloorplanDetails } from "@/axios/put-request";
import type { FloorPlanType } from "@/types";

interface Props {
	fullViewHandler: (value: boolean) => void;
	floorPlans: FloorPlanType;
}

const style = {
    width: "100%",
    height: "500px",
    display: "flex",
    alignItems: "flex-start",
};

const canvasStyle = {
    width: "100%",
    position: "absolute",
    inset: 0,
} as CSSProperties;

type FloorplanGroupType = Array<{ type: FloorPlanObjectListType; text: string; icon: JSX.Element; element_id?: string; id?: string }>;

const searchGroup = [
    ...fillSpaceObjectElements.table_chairs__category,
    ...fillSpaceObjectElements.entertainment__category,
    ...fillSpaceObjectElements.food_drinks__category,
    ...fillSpaceObjectElements.stage__category,
    ...fillSpaceObjectElements.doors__category,
    ...fillSpaceObjectElements.gifts__category,
];

function FloorPlan({ fullViewHandler, floorPlans }: Props) {
    const [pickElementStage, setPickElementStage] = useState(fillSpaceElements[0].type);
    const { selectedEventId, activeCeremonyId } = useEvent();
    const { loadingToast, updateToast } = useToast();
    const [loading, setLoading] = useState(false);
    const [editor, setEditor] = useState<Editor | null>(null);
    const toastId = useRef("loading_floorplan_id");
    const {
        deleteFloorPlanHandler,
        floorPlanStage,
        activeFloorPlanStageId,
        updateFloorPlanName,
        updateActiveFloorPlanStageId,
        addDefaultFloorPlanHandler,
        updateFloorPlanStageHandler,
    } = useFloorPlan();
    const [searchText, setSearchText] = useState("");
    const [searchResult, setSearchResult] = useState<FloorplanGroupType>([]);
    const [isSearching, setIsSearching] = useState(false);
    const [floorPlan, setFloorPlan] = useState<FloorPlanType[0] | null>(null);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const ref = useRef(null);
    useClickOutside(ref, unFocusFloorplan);

    function unFocusFloorplan() {
        setActiveIndex(null);
    }

    const eventFloorPlans = floorPlanStage.filter((item) => item.event_id === selectedEventId);

    useEffect(() => {
        if (activeFloorPlanStageId) {
            const currentFloorPlan = floorPlanStage.filter((item) => item.id === activeFloorPlanStageId);
            if (currentFloorPlan && currentFloorPlan.length > 0) setFloorPlan(currentFloorPlan[0]);
        } else if (floorPlans && floorPlans.length > 0) {
            setFloorPlan(floorPlans[0]);
        }
    }, [activeFloorPlanStageId]);

    const updateEditorOnMount = useCallback(
        (editorInstance: Editor) => {
            setEditor(editorInstance);
            // if (floorPlan) {
            //     if (floorPlan.stage) {
            //         const FloorplanData = JSON.parse(floorPlan.stage);
            //         loadSnapshot(editorInstance.store, FloorplanData);
            //     }
            // }
        },
        [floorPlan, floorPlanStage?.length, activeFloorPlanStageId],
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function renameFloorPlanHandler(event: any) {
        if (activeFloorPlanStageIndex !== null) {
            updateFloorPlanName(event?.target?.value, activeFloorPlanStageIndex);
        }
    }

    async function saveFloorPlanHandler() {
        try {
            if (!editor || !floorPlan) return;
            setLoading(true);
            const { document, session } = getSnapshot(editor.store);
            const floorPlanCanvasDetails = {
                stage: JSON.stringify({ document, session }),
            };
            await updateFloorplanDetails(floorPlan?.id, selectedEventId, floorPlanCanvasDetails);
            updateFloorPlanStageHandler(floorPlan?.id, floorPlanCanvasDetails.stage);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    // const myAssetStore: TLAssetStore = {
    //     async upload(asset, file) {
    //         const id = uniqueId();

    //         const formData = new FormData();
    //         formData.append("file", file);

    //         const objectName = `${id}-${file.name}`.replaceAll(/\W/g, "-");

    //         const requestData = await uploadFloorPlanElementRequest(formData, floorPlanId, objectName);

    //         const imageUrl = requestData.data.result.url;

    //         return imageUrl;
    //     },

    //     resolve(asset) {
    //         return asset.props.src;
    //     },
    // };

    const pickElementStageValue = pickElementStage as
		| "entertainment__category"
		| "food_drinks__category"
		| "stage__category"
		| "doors__category"
		| "gifts__category";

    const floorPlanGroupElements = fillSpaceObjectElements[pickElementStageValue] as Array<{
		title?: string;
		type: FloorPlanObjectListType;
		icon: JSX.Element;
		url?: string;
		id?: string;
		element_id: string;
	}>;

    useEffect(() => {
        if (searchText) {
            setIsSearching(true);
            const searcher = new FuzzySearch(searchGroup, ["text"], {
                caseSensitive: false,
            });
            const result = searcher.search(searchText);
            setSearchResult(result);
            if (result) {
                setIsSearching(false);
            }
        }
    }, [searchText.length]);

    const activeFloorPlanStageIndex = activeFloorPlanStageId ? floorPlanStage.findIndex((item) => item.id === activeFloorPlanStageId) : null;

    function searchTextHandler(event: ChangeEvent<HTMLInputElement>) {
        setSearchText(event.target.value);
    }

    function addFloorPlanHandler(name: string, id: string) {
        return {
            id,
            floor_plan_name: name ? name : "New Floor Plan",
            floor_plan_icon: "",
            stage: "",
        };
    }

    const floorPlanStageCount = floorPlanStage.length > 0 ? floorPlanStage.length + 1 : "";
    const newFloorplanName = `New Floor plan ${floorPlanStageCount}`;

    async function addNewFloorPlanHandler() {
        try {
            const floorPlanId = uuidv4();
            addDefaultFloorPlanHandler(newFloorplanName, floorPlanId, selectedEventId, activeCeremonyId);
            const floorPlanDetails = addFloorPlanHandler(newFloorplanName, floorPlanId);
            await createFloorPlanRequest(selectedEventId, {
                ...floorPlanDetails,
                event_id: selectedEventId,
                ceremony_id: activeCeremonyId,
            });
        } catch (error) {
            console.log("error", error);
        }
    }

    async function onDeleteFloorPlanHandler() {
        try {
            if (activeFloorPlanStageId && floorPlan) {
                loadingToast(toastId);
                await deleteFloorplanRequest(activeFloorPlanStageId, selectedEventId);
                indexedDB.deleteDatabase(`TLDRAW_DOCUMENT_v2floor_plan_${activeFloorPlanStageId}`);
                const nextFloorPlan = floorPlanStage[floorPlanStage.length - 1];
                deleteFloorPlanHandler(floorPlan.id);
                if (nextFloorPlan?.id) updateActiveFloorPlanStageId(nextFloorPlan.id);
                updateToast(toastId, "success", "Floor plan deleted");
            }
        } catch (error) {
            console.log("error", error);
            updateToast(toastId, "success", "Error deleting floor plan");
        }
    }

    function addFloorplanElement(elementId: string) {
        if (!editor) return;
        if (elementId) {
            const randomNumber = Math.random() * 200;
            const zoomLevel = editor.getZoomLevel();
            const height = 100;
            const width = 100;
            const scaledHeight = height / zoomLevel;
            const scaledWidth = width / zoomLevel;

            editor.createShape({
                type: elementId,
                x: editor.getViewportScreenCenter().x + randomNumber,
                y: editor.getViewportScreenCenter().y + randomNumber,
                props: {
                    h: scaledHeight,
                    w: scaledWidth,
                    color: "grey",
                },
            });
        }
    }

    const currentFloorPlanDetail = activeFloorPlanStageId ? floorPlanStage.filter((item) => item.id === activeFloorPlanStageId)[0] : null;

    // Helper function: Convert Blob to Data URL
    function blobToDataURL(blob: Blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }

    async function exportFloorPlanAsPDF() {
        try {
            if (!editor) return;
            const shapeIds = editor.getCurrentPageShapeIds() as unknown as TLShapeId[];
            const uriBlob = await exportToBlob({ editor, format: "png", ids: [...shapeIds], opts: { background: false } });
            const pdfInstance = new jsPDF({
                orientation: "portrait",
                unit: "px",
                format: "a4",
                compress: true,
            });

            const pdf = pdfInstance;
            const img = new Image();
            const dataUrl = (await blobToDataURL(uriBlob)) as string;

            img.src = dataUrl;
            await img.decode(); // Wait for the image to fully load

            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();
            pdf.setFillColor(255, 255, 255); // RGB for white
            pdf.rect(0, 0, pageWidth, pageHeight, "F");

            // Scale image to fit within the page if necessary
            let imgWidth = img.width;
            let imgHeight = img.height;
            const aspectRatio = img.width / img.height;

            if (imgWidth > pageWidth) {
                imgWidth = pageWidth;
                imgHeight = pageWidth / aspectRatio;
            }
            if (imgHeight > pageHeight) {
                imgHeight = pageHeight;
                imgWidth = pageHeight * aspectRatio;
            }
            pdf.addImage(dataUrl, "PNG", 0, 0, imgWidth, imgHeight);
            const logoImg = new Image();
            logoImg.src = logo;
            logoImg.height = 20;
            logoImg.width = 100;
            pdf.addImage(logoImg, "PNG", 20, 10, 100, 20);
            pdf.save(`${currentFloorPlanDetail?.floor_plan_name}-floor-plan.pdf`);
        } catch (error) {
            console.log("error", error);
        }
    }

    return (
        <section className="floor_plan">
            <div className="floor_plan_group">
                <div className="floor_plan_left_group">
                    <div className="added_floor_plans">
                        {eventFloorPlans?.map((item, index) => {
                            const className = activeFloorPlanStageIndex === index ? "current" : "";
                            const inputClassName = index === activeIndex ? "active" : "";
                            return (
                                <button
                                    key={index}
                                    onClick={() => updateActiveFloorPlanStageId(item.id)}
                                    className={className}
                                    onDoubleClick={() => setActiveIndex(index)}>
                                    <SquareDashedIcon />{" "}
                                    {index === activeIndex ? (
                                        <input
                                            value={item.floor_plan_name ? item.floor_plan_name : "New Floor Plan"}
                                            placeholder="New Floor Plan"
                                            className={inputClassName}
                                            ref={ref}
                                            onChange={renameFloorPlanHandler}
                                        />
                                    ) : (
                                        <span> {item.floor_plan_name ? item.floor_plan_name : "New Floor Plan"} </span>
                                    )}
                                </button>
                            );
                        })}
                    </div>
                    <button onClick={addNewFloorPlanHandler}>
                        <SquareDashedIcon /> Add new
                    </button>
                </div>
                <div className="right_button_group d-flex align-items-center">
                    <button onClick={saveFloorPlanHandler} className="p-0 mr-4">
                        {loading ? <Spinner className="w-25" /> : <SaveIcon />} Save
                    </button>
                    <SiteDropdown title={<ThreeDotsIcon />} className="custom three_dot no_hover_tick">
                        <Dropdown.Item onClick={exportFloorPlanAsPDF}>Download Floor plan</Dropdown.Item>
                        <Dropdown.Item onClick={onDeleteFloorPlanHandler}>Delete Floor plan</Dropdown.Item>
                    </SiteDropdown>
                </div>
            </div>
            <div className="floor_plan_view">
                {floorPlanStage?.length === 0 ? (
                    <div className="floor_plan_canvas_view">
                        <div className="no_floor_plan_card">
                            <div className="image_wrapper">
                                <SquareDashedIcon />
                            </div>
                            <div className="floor_plan_text">
                                <h4>You have no Floor Plan yet :(</h4>
                                <p>Draw your floor plan from scratch using our custom objects and elements and your creativity.</p>
                            </div>
                            <div className="button_group">
                                {/* <button className="btn_bordered">Request a Plan</button> */}
                                <button className="btn_red" onClick={addNewFloorPlanHandler}>
									Create Floor Plan
                                    <PlusIcon fill="#fff" />
                                </button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="canvas__view">
                        <div className="canvas_wrapper" style={{ height: "unset" }}>
                            <div className="canvas_board" style={{ height: "unset", width: "100%" }}>
                                <div className="draw_actions_top"></div>
                                <div style={style}>
                                    <div style={canvasStyle}>
                                        <Tldraw
                                            persistenceKey={`floor_plan_${activeFloorPlanStageId}`}
                                            shapeUtils={FloorplanCustomShapes}
                                            onMount={updateEditorOnMount}
                                            options={{ maxPages: 1, defaultSvgPadding: 20 }}
                                        />
                                    </div>
                                </div>
                                <div className="draw_actions_bottom">
                                    <div className="draw_button_wrapper">
                                        <button onClick={() => fullViewHandler(true)}>
                                            <ExpandIcon />
                                        </button>
                                        <button onClick={() => fullViewHandler(false)}>
                                            <ShrinkIcon />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pick_elements">
                            <div className="pick_elements_top_bar">
                                <div className="top_bar_header">
                                    {fillSpaceElements.map((item) => {
                                        const className = item.type === pickElementStage ? "active" : "";
                                        return (
                                            <button
                                                key={item.type}
                                                className={`pick_element ${className}`}
                                                onClick={() => setPickElementStage(item.type)}>
                                                {item.icon} <span>{item.text}</span>
                                            </button>
                                        );
                                    })}
                                </div>
                                <div className="right_view">
                                    <div className="search_tools_element">
                                        <Search fill="#D9D9D9" /> <input placeholder="Search" value={searchText} onChange={searchTextHandler} />
                                        {isSearching && (
                                            <div className="spinner_element_wrapper">
                                                <Spinner className="mx-0" />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="elements_group">
                                {searchText ? (
                                    searchResult.length > 0 ? (
                                        searchResult.map((item, index) => (
                                            <button key={index} onClick={() => addFloorplanElement(item?.element_id as string)}>
                                                {item.icon}
                                            </button>
                                        ))
                                    ) : (
                                        <p className="no_element">No element for this search query</p>
                                    )
                                ) : (
                                    <>
                                        {pickElementStage === "table_chairs__category" ? (
                                            <div className="table_chairs_elements">
                                                <FloorPlanElement elementGroup="chairs" addFloorPlanElementHandler={addFloorplanElement} />
                                                <FloorPlanElement elementGroup="square_tables" addFloorPlanElementHandler={addFloorplanElement} />
                                                <FloorPlanElement elementGroup="round_tables" addFloorPlanElementHandler={addFloorplanElement} />
                                                <FloorPlanElement elementGroup="rectangle_tables" addFloorPlanElementHandler={addFloorplanElement} />
                                            </div>
                                        ) : (
                                            floorPlanGroupElements.map((item, index) => {
                                                return (
                                                    <button key={index} onClick={() => addFloorplanElement(item.element_id)}>
                                                        {item.icon}
                                                    </button>
                                                );
                                            })
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
}

export default memo(FloorPlan);
