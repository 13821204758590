import { SofaElement } from "./FloorplanElements/SofaElement";
import { SingleSofaElement } from "./FloorplanElements/SingleSofaElement";
import { DoubleSofaElement } from "./FloorplanElements/DoubleSofaElement";
import { RoundTable10Element } from "./FloorplanElements/RoundTable10Element";
import { RoundTable8Element } from "./FloorplanElements/RoundTable8Element";
import { RectangleTable6Element } from "./FloorplanElements/RectangleTable6Element";
import { RectangleTable8Element } from "./FloorplanElements/RectangleTable8Element";
import { RectangleTable10Element } from "./FloorplanElements/RectangleTable10Element";
import { RectangleTable3Element } from "./FloorplanElements/RectangleTable3Element";
import { GiftElement } from "./FloorplanElements/GiftElement";
import { SingleClosedDoorElement } from "./FloorplanElements/SingleClosedDoorElement";
import { SingleOpenDoorElement } from "./FloorplanElements/SingleOpenDoorElement";
import { DoubleOpenDoorElement } from "./FloorplanElements/DoubleOpenDoorElement";
import { DoubleClosedDoorElement } from "./FloorplanElements/DoubleClosedDoorElement";
import { StageStairsElement } from "./FloorplanElements/StageStairsElement";
import { StageElement } from "./FloorplanElements/StageElement";
import { CateringStationElement } from "./FloorplanElements/CateringStationElement";
import { DessertElement } from "./FloorplanElements/DessertElement";
import { DrinksElement } from "./FloorplanElements/DrinksElement";
import { CocktailElement } from "./FloorplanElements/CocktailElement";
import { CakeElement } from "./FloorplanElements/CakeElement";
import { BuffetElement } from "./FloorplanElements/BuffetElement";
import { BandElement } from "./FloorplanElements/BandElement";
import { DJElement } from "./FloorplanElements/DJElement";
import { SoundElement } from "./FloorplanElements/SoundElement";
import { DanceFloorElement } from "./FloorplanElements/DanceFloorElement";
import { FloorplanLogo } from "./FloorplanElements/FloorplanLogo";
import { RectangleTable } from "./FloorplanElements/RectangleTable";
import { RoundTable } from "./FloorplanElements/RoundTable";
import { SquareTable } from "./FloorplanElements/SquareTable";

const FloorplanCustomShapes = [
    RectangleTable,
    RoundTable,
    SquareTable,
    FloorplanLogo,
    SofaElement,
    SingleSofaElement,
    DoubleSofaElement,
    RoundTable10Element,
    RoundTable8Element,
    RectangleTable3Element,
    RectangleTable6Element,
    RectangleTable8Element,
    RectangleTable10Element,
    GiftElement,
    SingleClosedDoorElement,
    SingleOpenDoorElement,
    DoubleOpenDoorElement,
    DoubleClosedDoorElement,
    StageStairsElement,
    StageElement,
    CateringStationElement,
    DessertElement,
    DrinksElement,
    CocktailElement,
    CakeElement,
    BuffetElement,
    BandElement,
    DJElement,
    SoundElement,
    DanceFloorElement,
];

export default FloorplanCustomShapes;
