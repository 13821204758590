/* eslint-disable max-len */
import React from "react";

export default function CompletedIcon() {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_10840_16051)">
                <path
                    d="M12.8333 6.46309V6.99976C12.8326 8.25767 12.4253 9.48165 11.6721 10.4892C10.9189 11.4967 9.86021 12.2337 8.65392 12.5904C7.44763 12.947 6.15836 12.9042 4.9784 12.4683C3.79844 12.0323 2.79101 11.2266 2.10635 10.1714C1.4217 9.11611 1.0965 7.8678 1.17927 6.61261C1.26204 5.35742 1.74833 4.16262 2.56562 3.20638C3.38292 2.25015 4.48742 1.58373 5.7144 1.30651C6.94139 1.02929 8.22512 1.15612 9.37413 1.66809M5.24996 6.41642L6.99996 8.16642L12.8333 2.33309"
                    stroke="#34A853"
                    strokeWidth="1.16667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_10840_16051">
                    <rect width="14" height="14" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
