import { ChangeEvent } from "react";
import { v4 as uuidv4 } from "uuid";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { type FloorPlanObjectListType } from "@/data/types";
import { FloorPlanType } from "@/types";

type useUIType = {
    sidebar: boolean;
    toggleSidebar: () => void;
    activeFloorPlanElementId: string | null;
    selectActiveFloorPlanElementId: (value: string | null) => void;
    activeFloorPlanStageId: string | null;
    updateActiveFloorPlanStageId: (value: string | null) => void;
    floorPlanStage: FloorPlanType;
    setFloorPlanStage: (value: FloorPlanType | []) => void;
    updateFloorPlanHandler: (value: ChangeEvent<HTMLInputElement>, index: number) => void;
    addMoreFloorPlanHandler: (event_id: string, ceremony_id: string) => void;
    updateFloorPlanStageHandler: (floorPlanId: string, stage: string) => void;
    addDefaultFloorPlanHandler: (name: string, id: string, event_id: string, ceremony_id: string) => void;
    deleteFloorPlanHandler: (floorPlanId: string) => void;
    updateFloorPlanName: (floorPlanName: string, floorPlanStageIndex: number) => void;
    selectActiveObjectHandler: (floorPlanIndex: number, objectType: FloorPlanObjectListType | null) => void;
};

const useFloorPlan = create<useUIType>()(
    persist(
        (set) => ({
            sidebar: true,
            toggleSidebar: () => set(({ sidebar }) => ({ sidebar: !sidebar })),
            activeFloorPlanElementId: null,
            selectActiveFloorPlanElementId: (selectedElementId: string | null) => set(() => ({ activeFloorPlanElementId: selectedElementId })),
            activeFloorPlanStageId: null,
            updateActiveFloorPlanStageId: (selectedStageId: string | null) => set(() => ({ activeFloorPlanStageId: selectedStageId })),
            floorPlanStage: [],
            setFloorPlanStage: (value: FloorPlanType | []) => set(() => ({ floorPlanStage: value })),
            updateFloorPlanHandler: (event: ChangeEvent<HTMLInputElement>, index: number) =>
                set(({ floorPlanStage }) => {
                    floorPlanStage[index] = {
                        ...floorPlanStage[index],
                        [event.target.name]: event.target.value,
                        stage: "",
                    };
                    return {
                        floorPlanStage,
                    };
                }),
            updateFloorPlanStageHandler: (floorPlanId: string, stage: string) =>
                set(({ floorPlanStage }) => {
                    const selectedFloorPlanIndex = floorPlanStage.findIndex((item) => item.id === floorPlanId);
                    if (selectedFloorPlanIndex === -1) return { floorPlanStage };
                    floorPlanStage[selectedFloorPlanIndex] = {
                        ...floorPlanStage[selectedFloorPlanIndex],
                        stage,
                    };
                    return {
                        floorPlanStage,
                    };
                }),
            addMoreFloorPlanHandler: (event_id, ceremony_id) =>
                set(({ floorPlanStage }) => {
                    return {
                        floorPlanStage: [
                            ...floorPlanStage,
                            {
                                floor_plan_name: "",
                                id: uuidv4(),
                                floor_plan_icon: "",
                                stage: "",
                                event_id,
                                ceremony_id,
                            },
                        ],
                    };
                }),
            addDefaultFloorPlanHandler: (name, id, event_id, ceremony_id) =>
                set(({ floorPlanStage }) => {
                    return {
                        activeFloorPlanStageId: id,
                        floorPlanStage: [
                            ...floorPlanStage,
                            {
                                floor_plan_name: name ? name : "New Floor Plan",
                                id: id,
                                floor_plan_icon: "",
                                stage: "",
                                event_id,
                                ceremony_id,
                            },
                        ],
                    };
                }),
            updateFloorPlanName: (floorPlanStageName: string, floorPlanStageIndex: number) =>
                set(({ floorPlanStage }) => {
                    floorPlanStage[floorPlanStageIndex] = {
                        ...floorPlanStage[floorPlanStageIndex],
                        floor_plan_name: floorPlanStageName,
                    };
                    return {
                        floorPlanStage,
                    };
                }),
            selectActiveObjectHandler: (floorPlanIndex: number, objectType: FloorPlanObjectListType | null) =>
                set(({ floorPlanStage }) => {
                    if (floorPlanStage[floorPlanIndex]?.active_object_type) floorPlanStage[floorPlanIndex].active_object_type = objectType;

                    return { floorPlanStage };
                }),
            deleteFloorPlanHandler: (floorPlanId: string) =>
                set(({ floorPlanStage }) => {
                    const updatedFloorPlan = floorPlanStage.filter((item) => item?.id !== floorPlanId);
                    return {
                        floorPlanStage: updatedFloorPlan,
                    };
                }),
        }),
        { name: "floor_plan", storage: createJSONStorage(() => localStorage) },
    ),
);

export default useFloorPlan;
