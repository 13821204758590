import axios from "axios";

import type {
    Asoebi_order_status,
    CollaboratorPaymentPlan,
    CollaboratorPlanStatus,
    FloorPlanType,
    RequestErrorType,
    VendorType,
    costItemType,
    EventType,
    TodoType,
    userType,
    EventdayFlowType,
    PlanningTimelineType,
} from "@/types";
import { AxiosResponseNoDataType, AxiosResponseType } from "@/data/types";

const BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

type inviteUserPartnerType = {
    collaborators: Array<{
        email: string;
        role: string;
        event_ids: string[];
        name?: string;
        planMonthDuration?: number;
        permission?: string;
        payment_plan?: keyof typeof CollaboratorPaymentPlan | string;
        send_plan_expiration_email?: boolean;
        plan_status?: keyof typeof CollaboratorPlanStatus | string;
        start_plan_date?: string;
        end_plan_date?: string | null;
    }>;
    inviteeEmail: string;
    inviteeRole: string;
    inviteeName: string;
    eventId: string;
};

export async function inviteCollaborator(
    id: string,
    { eventId, collaborators, inviteeEmail, inviteeName, inviteeRole }: inviteUserPartnerType,
    newUI?: boolean,
): Promise<AxiosResponseNoDataType> {
    try {
        const url = newUI ? `${BASE_URL}/api/v1/user/invite-collaborator/${id}?mode=new` : `${BASE_URL}/api/v1/user/invite-collaborator/${id}`;
        const data = await axios.put(url, {
            collaborators,
            eventId,
            inviteeName,
            inviteeEmail,
            inviteeRole,
        });
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response?.data?.message);
    }
}

export async function updateUserChecklist(
    checklist: {
        id: string;
        name?: string;
        due?: Date | null;
        ceremony?: { id: string; name: string };
        isDone: boolean;
        note?: string | null;
    },
    ceremonyId: string,
    eventId: string,
): Promise<AxiosResponseNoDataType> {
    try {
        const data = await axios.put(`${BASE_URL}/api/v1/event/${eventId}/checklist/update/${ceremonyId}`, { checklist });
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function updateCostItems(categoryId: string, costItem: costItemType, eventId: string): Promise<AxiosResponseNoDataType> {
    try {
        const data = await axios.put(`${BASE_URL}/api/v1/event/${eventId}/update-cost-item`, { cost_item: costItem, category_id: categoryId });
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function editUserProfile(body: { email: string; name?: string }): Promise<AxiosResponseNoDataType> {
    try {
        const data = await axios.put(`${BASE_URL}/api/v1/user/edit-profile`, body);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

type updateCeremonyBodyType = {
    id?: string;
    name?: string;
    location?: string | null;
    no_of_guests?: string | number | null;
    date?: Date | null;
    checklist?: TodoType | undefined;
    show_suggestion?: boolean | undefined;
};

export async function updateCeremony(ceremony: updateCeremonyBodyType, ceremonyId: string, eventId: string) {
    try {
        const data = await axios.put(`${BASE_URL}/api/v1/event/${eventId}/ceremony/update/${ceremonyId}`, ceremony);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function updateAuditUsername(name: string, eventId: string, user_id: string) {
    try {
        const data = await axios.put(`${BASE_URL}/api/v1/update-audit-username/${eventId}`, { name, user_id });
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function deactiveEvent(eventId: string, expired_collaborator_email: string) {
    try {
        const data = await axios.put(`${BASE_URL}/api/v1/deactivate-event/${eventId}`, { expired_collaborator_email });
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

type costItemDetailsType = { name: string; category_id: string };

export async function updateCostItemName(eventId: string, costItemId: string, costItemDetails: costItemDetailsType) {
    try {
        const data = await axios.put(`${BASE_URL}/api/v1/event/${eventId}/update-cost-item-title/${costItemId}`, costItemDetails);
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function updateBudgetCategoryTitle(eventId: string, categoryId: string, title: string) {
    try {
        const data = await axios.put(`${BASE_URL}/api/v1/event/${eventId}/update-category-title/${categoryId}`, { title });
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function updateOnboardingDetails(userId: string, onboardingDetails: Partial<userType["onboarding"]>) {
    try {
        const data = await axios.put(`${BASE_URL}/api/v1/user/${userId}/onboard`, onboardingDetails);
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function updateUserDetails(userId: string, userDetails: Partial<userType>) {
    try {
        const data = await axios.put(`${BASE_URL}/api/v1/update-user/${userId}`, userDetails);
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function updateOrderStatus(orderId: string, status: keyof typeof Asoebi_order_status) {
    try {
        const data = await axios.put(`${BASE_URL}/api/v1/update-asoebi-order-status/${orderId}`, { status });
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function editUserEventDetails(eventId: string, eventData: Partial<EventType>) {
    try {
        const data = await axios.put(`${BASE_URL}/api/v1/update-event-details/${eventId}`, eventData);
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function updateVendorDetails(vendorId: string, vendorDetails: Partial<VendorType>) {
    try {
        const data = await axios.put(`${BASE_URL}/api/v1/update-vendor/${vendorId}`, vendorDetails);
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function updateMultipleCeremonyDetails(eventId: string, ceremonyDetails: Partial<EventType["ceremonies"]>) {
    try {
        const data = await axios.put(`${BASE_URL}/api/v1/update-multiple-ceremony-details/${eventId}`, ceremonyDetails);
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function updateFloorplanDetails(floorplanId: string, eventId: string, ceremonyDetails: Partial<FloorPlanType[0]>) {
    try {
        const data = await axios.put(`${BASE_URL}/api/v1/${eventId}/update-floorplan/${floorplanId}`, ceremonyDetails);
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function updateEventDayFlowDetails(
    eventDayFlowId: string,
    eventId: string,
    ceremonyId: string,
    eventdayFlowDetails: Partial<EventdayFlowType[0]>,
) {
    try {
        const data = await axios.put(
            `${BASE_URL}/api/v1/event/${eventId}/ceremony/${ceremonyId}/event-day-flow/${eventDayFlowId}`,
            eventdayFlowDetails,
        );
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function editPlanningTimelineRequest(
    data: PlanningTimelineType[0],
    { eventId, planningTimelineId }: { eventId: string; planningTimelineId: string },
): Promise<AxiosResponseType<{ message: string; status: boolean }>> {
    try {
        return await axios.put(`${BASE_URL}/api/v1/event/${eventId}/ceremony/${data.ceremony_id}/planning-timeline/${planningTimelineId}`, data);
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}
