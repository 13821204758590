import { useState } from "react";
import logo from "../assets/img/logo.png";
import ham from "../assets/img/hamburger.png";
import hamx from "../assets/img/hamx.png";
import SiteDropdown from "./Dropdown";

export default function NavBar() {
    const [openNav, setOpenNav] = useState(false);
    const openMobileNav = () => {
        setOpenNav(!openNav);
    };

    const navClassname = openNav ? "side-nav-link" : "side-nav close-mobile-menu";

    return (
        <header className="fluid-container">
            <div className="navbar">
                <div className="container d-flex justify-content-space-between">
                    <div className="navbar-brand logo">
                        <a href={`${process.env.REACT_APP_PLANADAY_BASE_URL}`}>
                            <img src={logo} alt="Planaday logo" className="logo-img" />
                        </a>
                    </div>
                    <div className={openNav ? "main-nav" : "justify-content-end main-nav "}>
                        <a href={`${process.env.REACT_APP_PLANADAY_BASE_URL}/about.html`} className="join-btn btn text-color_base">
                            About
                        </a>
                        <SiteDropdown
                            id="community_dropdown_desktop"
                            title="Community"
                            variant="clear"
                            className="btn nav_dropdown text-color_base bold border-0 text-base"
                            options={[
                                {
                                    text: "Ideas & Advice",
                                    link: "https://www.blog.planaday.events",
                                    target: "_blank",
                                },
                                {
                                    text: "Forum (coming soon)",
                                    link: "/",
                                    target: "_self",
                                },
                            ]}
                        />
                    </div>
                    <div className="side-nav justify-content-end">
                        <img src={ham} className={openNav ? "hide-nav-first" : "side-nav-first"} onClick={openMobileNav} alt="open_nav" />
                        <img src={hamx} className={openNav ? "side-nav-second" : "hide-nav-second"} onClick={openMobileNav} alt="close_nav" />
                    </div>
                </div>
            </div>

            <div>
                <div className={navClassname}>
                    <a href={`${process.env.REACT_APP_PLANADAY_BASE_URL}/about.html`}
                        className="side-nav-btn btn close-mobile-menu-top" target="_blank">
                        About
                    </a>
                    <SiteDropdown
                        id="community_dropdown"
                        title="Community"
                        variant="clear"
                        className="btn nav_dropdown text-color_base normal border-0 text-base"
                        options={[
                            {
                                text: "Ideas & Advice",
                                link: "https://www.blog.planaday.events",
                                target: "_blank",
                            },
                            {
                                text: "Forum (coming soon)",
                                link: "/",
                                target: "_self",
                            },
                        ]}
                    />
                </div>
            </div>
        </header>
    );
}
