import { useQuery } from "@tanstack/react-query";
import { Dropdown } from "react-bootstrap";
import { ChangeEvent, Suspense, useEffect, useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon, FlagTriangleRightIcon, PencilIcon, PlusIcon, Trash2Icon } from "lucide-react";
import { format } from "date-fns";

import { useEvent } from "@/hooks/useEvent";
import { getEvent } from "@/axios/get-request";
import { EventdayFlowType, EventType, VendorType } from "@/types";
import FilterDropdown from "@/components/FilterDropdown";
import AddPlanningTimelineModal from "@/components/modals/AddPlanningTimelineModal";
import EditPlanningTimelineModal from "@/components/modals/EditPlanningTimelineModal";
import ThreedotIcon from "@/assets/icon/ThreedotIcon";
import { generateColorFromId } from "@/helper";
import VendorPicker from "./modals/VendorPicker";
import PlanningStageDropdown from "./PlanningStageDropdown";
import DeletePlanningTimelineModal from "./modals/DeletePlanningTimelineModal";
import "@/styles/checklist_new.scss";
import "@/styles/event_day_flow.scss";
import "@/styles/planning_timeline.scss";

enum ModalList {
    add_planning_timeline_modal = "add_planning_timeline_modal",
    edit_planning_timeline_modal = "edit_planning_timeline_modal",
    delete_planning_timeline_modal = "delete_planning_timeline_modal",
}

const timelineHeader = ["Item", "Stage", "Deadline", "Tag", "Owner", "Notes"];

const Months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

type planStage = "not_done" | "ongoing" | "done";

type EventDataType = {
    message: string;
    status: number;
    result: EventType | null;
};

type FormValuesType = {
    id: string;
    task: string;
    stage: planStage;
    deadline: null;
    tag: string;
    owner: VendorType | null;
    note: string;
};

export default function PlanningTimeline() {
    const [modal, setModal] = useState<string | null>(null);
    const { selectedEventId, activeCeremonyId, setActiveCeremonyId } = useEvent();
    const [counter, setCounter] = useState({
        start: 0,
        end: 6,
    });
    const { data, status, isRefetching } = useQuery({
        queryKey: [`ceremony_${activeCeremonyId}_${selectedEventId}`],
        queryFn: () => getEvent(selectedEventId),
    });
    const [formValues, setFormValues] = useState<Array<FormValuesType>>([
        {
            id: "",
            task: "",
            stage: "not_done",
            deadline: null,
            tag: "",
            owner: null,
            note: "",
        },
    ]);
    const [planTimelineToEdit, setPlanTimelineToEdit] = useState<FormValuesType | null>(null);

    function getInitialFormValues(eventData: EventDataType) {
        if (eventData) {
            const ceremonies = eventData?.result?.ceremonies;
            const selectedCeremony = activeCeremonyId ? ceremonies?.filter((item: { id: string }) => item.id === activeCeremonyId) : [];
            if (selectedCeremony && selectedCeremony[0].planning_timeline) {
                setFormValues(selectedCeremony[0].planning_timeline);
            }
        }
    }

    function updateStageHandler(planningStage: planStage, index: number) {
        const tempData = [...formValues];
        tempData[index] = {
            ...tempData[index],
            stage: planningStage,
        };
        setFormValues(tempData);
    }

    function updateInputHandler(event: ChangeEvent<HTMLInputElement>, index: number) {
        const tempData = [...formValues];
        tempData[index] = {
            ...tempData[index],
            [event.target.name]: event.target.value,
        };
        setFormValues(tempData);
    }

    function updateOwnerHandler(owner: VendorType | null, index: number) {
        const tempData = [...formValues];
        tempData[index] = {
            ...tempData[index],
            owner,
        };
        setFormValues(tempData);
    }

    function switchMonth() {
        setCounter((prev) => {
            if (prev.start === 0) {
                return {
                    ...prev,
                    start: prev.start + 6,
                    end: prev.end + 6,
                };
            } else if (prev.start === 6) {
                return {
                    ...prev,
                    start: prev.start - 6,
                    end: prev.end - 6,
                };
            }

            return prev;
        });
    }

    const eventData = data?.data;

    useEffect(() => {
        if (
            status === "success" &&
            activeCeremonyId === "all" &&
            eventData?.result?.ceremonies &&
            Array.isArray(eventData?.result?.ceremonies) &&
            eventData?.result?.ceremonies.length > 0
        ) {
            setActiveCeremonyId(eventData?.result?.ceremonies[0]?.id);
        }
        if (eventData && selectedCeremony && selectedCeremony[0]?.planning_timeline && selectedCeremony[0]?.planning_timeline[0].task) {
            getInitialFormValues(eventData);
        }
    }, [status, data, selectedEventId, isRefetching]);

    function onCloseModal() {
        setModal(null);
        if (planTimelineToEdit) {
            setPlanTimelineToEdit(null);
        }
    }

    function showPlanningTimelineModal() {
        setModal(ModalList.add_planning_timeline_modal);
    }

    const ceremonies = status === "success" && eventData?.result?.ceremonies ? eventData?.result.ceremonies : [];
    const selectedCeremony = activeCeremonyId ? ceremonies.filter((item) => item.id === activeCeremonyId) : [];
    const ceremonyName = selectedCeremony ? selectedCeremony[0]?.name : "";
    const eventName = eventData?.result ? eventData?.result?.event_name : "";
    const ceremonyChecklists: EventdayFlowType = [];
    if (selectedCeremony[0]?.eventdayFlow) {
        selectedCeremony[0]?.eventdayFlow.map((item) => {
            if (item.ceremony.id === activeCeremonyId) {
                ceremonyChecklists.push(item);
            }
        });
    }

    function onEditPlanTimelineHandler(timeline: FormValuesType) {
        setPlanTimelineToEdit(timeline);
        setModal(ModalList.edit_planning_timeline_modal);
    }

    function onDeletePlanTimelineHandler(timeline: FormValuesType) {
        setPlanTimelineToEdit(timeline);
        setModal(ModalList.delete_planning_timeline_modal);
    }

    return (
        <>
            <Suspense>
                {modal === ModalList.add_planning_timeline_modal && (
                    <AddPlanningTimelineModal eventName={eventName} ceremonyName={ceremonyName} onClose={onCloseModal} />
                )}
                {modal === ModalList.edit_planning_timeline_modal && planTimelineToEdit && (
                    <EditPlanningTimelineModal
                        eventName={eventName}
                        ceremonyName={ceremonyName}
                        selectedPlanTimeline={planTimelineToEdit}
                        onClose={onCloseModal}
                    />
                )}
                {modal === ModalList.delete_planning_timeline_modal && planTimelineToEdit && (
                    <DeletePlanningTimelineModal
                        planTimelineId={planTimelineToEdit.id}
                        timelineName={planTimelineToEdit.task}
                        onClose={onCloseModal}
                    />
                )}
            </Suspense>
            <section className="checklist_page planning_timeline event_day_flow">
                <div className="checklist_view mt-4">
                    <div className="title_row">
                        <div>
                            <FlagTriangleRightIcon />
                            <h3>Planning Timeline</h3>
                        </div>
                        <div className="button_row">
                            <FilterDropdown title={ceremonyName} disabled={!selectedEventId}>
                                {ceremonies.map((ceremony) => (
                                    <Dropdown.Item key={ceremony.id} onClick={() => setActiveCeremonyId(ceremony.id)}>
                                        {ceremony.name}
                                    </Dropdown.Item>
                                ))}
                            </FilterDropdown>
                        </div>
                    </div>
                    <div className="event_day_view">
                        <div className="months_group">
                            <button onClick={switchMonth}>
                                <ChevronLeftIcon />
                            </button>
                            <div className="monthly_task_view">
                                {Months.slice(counter.start, counter.end).map((month, index) => {
                                    const color = generateColorFromId(month);
                                    const style = { backgroundColor: color };
                                    return (
                                        <div className="month_view" key={index}>
                                            <div className="handlebar" style={style} />
                                            <h4>{month}</h4>
                                            <h6>
                                                00 <sup>Tasks</sup>
                                            </h6>
                                        </div>
                                    );
                                })}
                            </div>
                            <button onClick={switchMonth}>
                                <ChevronRightIcon />
                            </button>
                        </div>
                        <div className="this_month_list table_view">
                            <div className="table_row">
                                <h3>This month</h3>
                                <div className="action_group">
                                    <button onClick={showPlanningTimelineModal}>
                                        Add new <PlusIcon />
                                    </button>
                                    <button>
                                        <ThreedotIcon />
                                    </button>
                                </div>
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <td>
                                            <input type="checkbox" />
                                        </td>
                                        {timelineHeader.map((item, index) => (
                                            <td key={index}>{item}</td>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {formValues.map((item, index) => {
                                        const vendorDetails = {
                                            id: item.owner?.id ? item.owner?.id : "",
                                            name: item.owner?.name ? item.owner?.name : "",
                                        };
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <input type="checkbox" />
                                                </td>
                                                <td>
                                                    <input
                                                        placeholder="Item"
                                                        name="task"
                                                        onChange={(event) => updateInputHandler(event, index)}
                                                        value={item.task}
                                                    />
                                                </td>
                                                <td>
                                                    <PlanningStageDropdown
                                                        showLabel={false}
                                                        stage={item.stage}
                                                        updateStageHandler={(stage) => updateStageHandler(stage, index)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        name="deadline"
                                                        value={item.deadline ? format(new Date(item.deadline), "dd MM yyyy") : "--"}
                                                        placeholder="00 00 0000"
                                                        readOnly
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        name="tag"
                                                        value={item.tag}
                                                        placeholder="Tag"
                                                        onChange={(event) => updateInputHandler(event, index)}
                                                    />
                                                </td>
                                                <td>
                                                    <VendorPicker
                                                        addNew={false}
                                                        vendor={vendorDetails}
                                                        onSelectEventVendor={(owner) => updateOwnerHandler(owner, index)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        name="note"
                                                        value={item.note}
                                                        placeholder="--"
                                                        onChange={(event) => updateInputHandler(event, index)}
                                                    />
                                                </td>
                                                <td>
                                                    <div className="button_group">
                                                        <button onClick={() => onEditPlanTimelineHandler(item)}>
                                                            <PencilIcon />
                                                        </button>
                                                        <button onClick={() => onDeletePlanTimelineHandler(item)}>
                                                            <Trash2Icon />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        {/* <div className="next_month_list table_view">
                            <div className="table_row">
                                <h3>Next month</h3>
                                <div className="action_group">
                                    <button onClick={showPlanningTimelineModal}>
                                        Add new <PlusIcon />
                                    </button>
                                    <button>
                                        <ThreedotIcon />
                                    </button>
                                </div>
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <td>
                                            <input type="checkbox" />
                                        </td>
                                        {timelineHeader.map((item, index) => (
                                            <td key={index}>{item}</td>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <input type="checkbox" />
                                        </td>
                                        <td>
                                            <input />
                                        </td>
                                        <td>
                                            <div className="stage_element">
                                                <CircleDashedIcon />
                                                <input placeholder="Stage" />
                                            </div>
                                        </td>
                                        <td>
                                            <input placeholder="00 00 0000" />
                                        </td>
                                        <td>
                                            <input placeholder="Tag" />
                                        </td>
                                        <td>
                                            <VendorPicker addNew={false} vendor={vendorDetails} />
                                        </td>
                                        <td>
                                            <input placeholder="--" />
                                        </td>
                                        <td>
                                            <div className="button_group">
                                                <button>
                                                    <PencilIcon />
                                                </button>
                                                <button>
                                                    <Trash2Icon />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    );
}
