/* eslint-disable */
import {
	Geometry2d,
	RecordProps,
	Rectangle2d,
	ShapeUtil,
	SVGContainer,
	T,
	TLBaseShape,
	TLResizeInfo,
	resizeBox,
	TLDefaultColorStyle,
	DefaultColorStyle,
	getDefaultColorTheme,
} from "tldraw";
import { TLIconDefaultColor } from "@/helper";

type ICustomShape = TLBaseShape<
	"single_open_door",
	{
		w: number;
		h: number;
		color: TLDefaultColorStyle;
	}
>;

// [2]
export class SingleOpenDoorElement extends ShapeUtil<ICustomShape> {
	static override type = "single_open_door" as const;
	static override props: RecordProps<ICustomShape> = {
		w: T.number,
		h: T.number,
		color: DefaultColorStyle as any,
	};

	// [b]
	getDefaultProps(): ICustomShape["props"] {
		return {
			w: 15,
			h: 15,
			color: TLIconDefaultColor,
		};
	}

	// [c]
	override canEdit() {
		return true;
	}
	override canResize() {
		return true;
	}
	override isAspectRatioLocked() {
		return false;
	}

	// [d]
	getGeometry(shape: ICustomShape): Geometry2d {
		return new Rectangle2d({
			width: shape.props.w,
			height: shape.props.h,
			isFilled: true,
		});
	}

	// [e]
	override onResize(shape: ICustomShape, info: TLResizeInfo<any>) {
		return resizeBox(shape, info);
	}

	// [f]
	component(shape: ICustomShape) {
		const theme = getDefaultColorTheme({ isDarkMode: false });
		const themeColor = theme[shape.props.color] as { solid: string };
		const fill = themeColor.solid;

		return (
			<SVGContainer>
				<svg
					fill="none"
					viewBox="20.16 5.379310344827586 15.624000000000002 15.35344827586207"
					xmlns="http://www.w3.org/2000/svg"
					// style="max-height: 500px"
					width={shape.props.w}
					height={shape.props.h}>
					<mask fill="white" id="path-2-inside-1_11634_7326">
						<path d="M35.0037 19.9998C35.0037 18.1608 34.6414 16.3399 33.9377 14.6408C33.2339 12.9418 32.2024 11.3981 30.9021 10.0977C29.6017 8.79733 28.0579 7.76582 26.3589 7.06207C24.6599 6.35831 22.8389 5.99609 20.9999 5.99609L20.9999 19.9998L35.0037 19.9998Z" />
					</mask>
					<path
						mask="url(#path-2-inside-1_11634_7326)"
						strokeWidth="0.56"
						stroke="#404040"
						fillOpacity="0.8"
						fill={fill}
						d="M35.0037 19.9998C35.0037 18.1608 34.6414 16.3399 33.9377 14.6408C33.2339 12.9418 32.2024 11.3981 30.9021 10.0977C29.6017 8.79733 28.0579 7.76582 26.3589 7.06207C24.6599 6.35831 22.8389 5.99609 20.9999 5.99609L20.9999 19.9998L35.0037 19.9998Z"
					/>
				</svg>
			</SVGContainer>
		);
	}

	// [g]
	indicator() {
		return;
	}
}
