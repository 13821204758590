import { FloorPlanObjectListType } from "@/data/types";
import { floorPlanTableAndChairsElements } from "@/utils/draw_floor_plan_icons";
import React from "react";

type FloorplanGroupType = Array<{ type: FloorPlanObjectListType; text: string; icon: JSX.Element; element_id?: string; id?: string }>;

interface FloorPlanElementProps {
	elementGroup: "chairs" | "square_tables" | "round_tables" | "rectangle_tables";
	addFloorPlanElementHandler: (element_id: string) => void;
}

export default function FloorPlanElement({ elementGroup, addFloorPlanElementHandler }: FloorPlanElementProps) {
    return (
        <div className="floor_plan_element">
            <h6>{floorPlanTableAndChairsElements[elementGroup].title}</h6>
            <div className="table_chair_group mt-2">
                {floorPlanTableAndChairsElements[elementGroup].elements.map((item: FloorplanGroupType[0], index) => (
                    <button key={index} onClick={() => addFloorPlanElementHandler(item.element_id as string)}>
                        {item.icon}
                    </button>
                ))}
            </div>
        </div>
    );
}
