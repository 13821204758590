/* eslint-disable max-len */
import type { AxiosResponse } from "axios";
import {
    GetBudgetResponseType,
    GetUserChecklist,
    GetUserResponse,
    RequestErrorType,
    AsoebiOrderType,
    auditType,
    bankListType,
    EventType,
    eventWeatherArchiveType,
    resolveBankDetailsType,
    userType,
    AsoebiOrderFormType,
    VendorType,
} from "@/types";

import axios, { getUID } from "@/axios/config";
import { PLANADAY_GHOST_CMS_ID } from "@/utils/constant";
import { AsoebiAdminType, AxiosResponseType, EventFilterType, SubscriptionType } from "@/data/types";

const BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

export async function getUserDetails(): Promise<GetUserResponse | null> {
    const userId = getUID();
    if (!userId) {
        return null;
    }
    try {
        const user_details = await axios.get(`${BASE_URL}/api/v1/user/${userId}`);
        return user_details?.data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function getUserChecklist(eventId: string): Promise<GetUserChecklist | null> {
    try {
        const UID = getUID();
        if (!UID && !eventId) {
            return null;
        }
        const data = await axios.get(`${BASE_URL}/api/v1/user/checklist/${UID}?event_id=${eventId}`);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function getBudget(eventId: string): Promise<GetBudgetResponseType | null> {
    try {
        if (!eventId) {
            return null;
        }
        const data = await axios.get(`${BASE_URL}/api/v1/category/${eventId}`);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function getCeremonies(eventId: string) {
    try {
        if (!eventId) {
            return null;
        }
        const data: AxiosResponseType<EventType["ceremonies"]> = await axios.get(`${BASE_URL}/api/v1/event/${eventId}/ceremonies`);
        return data?.data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function getEventTags() {
    try {
        const data: AxiosResponse<{
            tags: Array<{ name: string; url: string }>;
        }> = await axios.get(`https://planaday-blog.ghost.io/ghost/api/content/tags/?key=${PLANADAY_GHOST_CMS_ID}`);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function getEventLocationCoordinates(location: string | null) {
    try {
        const locationQuery = location ? `?location=${location}` : "";
        const data = await axios.get(`${BASE_URL}/api/v1/get-event-coordinates${locationQuery}`);
        return data?.data;
    } catch (error) {
        throw new Error("Unable to fetch location co-ordinates");
    }
}

// date format 2023-08-10
export async function getEventWeatherForecast(
    coordinates: {
        latitude: number;
        longitude: number;
    },
    date: string | null,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
    try {
        const { latitude, longitude } = coordinates;

        const latitudeQuery = latitude ? `?latitude=${latitude}&date=${date}` : "";
        const longitudeQuery = longitude ? `&longitude=${longitude}` : "";
        const data = await axios.get(`${BASE_URL}/api/v1/get-event-weather-forecast${latitudeQuery}${longitudeQuery}`);
        return data?.data;
    } catch (error) {
        throw new Error("Unable to fetch event weather forecast");
    }
}

// date format 2023-08-10
export async function getEventWeatherArchiveData(
    coordinates: {
        latitude: number;
        longitude: number;
    },
    date: string | null,
): Promise<{ result: eventWeatherArchiveType }> {
    try {
        const { latitude, longitude } = coordinates;
        const latitudeQuery = latitude ? `?latitude=${latitude}&date=${date}` : "";
        const longitudeQuery = longitude ? `&longitude=${longitude}` : "";
        const data = await axios.get(`${BASE_URL}/api/v1/get-event-weather-forecast-archive${latitudeQuery}${longitudeQuery}`);
        return data?.data;
    } catch (error) {
        throw new Error("Unable to fetch event weather forecast");
    }
}

export async function getQuotes() {
    try {
        const data = await axios.get(`${BASE_URL}/api/v1/get-quotes`);
        return data;
    } catch (error) {
        throw new Error("Unable to fetch quotes");
    }
}

export async function getAudits(eventId: string): Promise<AxiosResponseType<auditType> | null> {
    try {
        if (!eventId) {
            return null;
        }
        const data = await axios.get(`${BASE_URL}/api/v1/audits/${eventId}`);
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function getEvents(
    onSuccess?: (data: { result: EventType[]; status: boolean }) => void,
    filterEvent?: EventFilterType,
): Promise<AxiosResponseType<EventType[] | null> | null> {
    try {
        const UID = getUID();
        if (!UID) {
            return null;
        }
        const EventURL = filterEvent ? `${BASE_URL}/api/v1/events?filter=${filterEvent}` : `${BASE_URL}/api/v1/events`;
        const data = await axios.get(EventURL);
        if (onSuccess) {
            onSuccess(data?.data);
        }
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response?.data?.message);
    }
}

export async function getEvent(eventId: string): Promise<AxiosResponseType<EventType | null> | null> {
    try {
        const UID = getUID();
        if (!UID || !eventId) {
            return null;
        }
        const data = await axios.get(`${BASE_URL}/api/v1/event/${eventId}`);
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response?.data?.message);
    }
}

export async function getOnboardingDetails(): Promise<{ result: userType["onboarding"] } | null> {
    try {
        const UID = getUID();
        if (!UID) {
            return null;
        }
        const data = await axios.get(`${BASE_URL}/api/v1/user-onboard-details`);
        return data?.data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function getAsoebiQuesionnaireDetails(id: string): Promise<{ result: AsoebiOrderFormType } | null> {
    try {
        if (!id) {
            return null;
        }
        const data = await axios.get(`${BASE_URL}/api/v1/asoebi-questionnaire/${id}`);
        return data?.data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function getListOfBanks(country = "nigeria"): Promise<{ result: bankListType }> {
    try {
        const data = await axios.get(`${BASE_URL}/api/v1/fetch-banks?country=${country}`);
        return data.data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function resolveBankDetails(
    accountNumber: string,
    bankCode: string,
): Promise<{ message: string; success: boolean; result: resolveBankDetailsType }> {
    try {
        const data = await axios.get(`${BASE_URL}/api/v1/resolve-bank-details?account_number=${accountNumber}&bank_code=${bankCode}`);
        return data.data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function getAsoebiAdminOverviewDetails(): Promise<{
    message: string;
    result: { no_of_planners: number; no_of_guest: number; total_amount_paid_by_guest: number; questionnaires: AsoebiOrderType[] };
}> {
    try {
        const data = await axios.get(`${BASE_URL}/api/v1/asoebi-admin-overview`);
        return data.data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function getAsoebiPlannerQuestionnaires(
    emailAddress: string,
): Promise<{ message: string; result: { planner_questionnaires: AsoebiOrderType[] } }> {
    try {
        const data = await axios.get(`${BASE_URL}/api/v1/planner-questionnaires?email=${emailAddress}`);
        return data.data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function getAsoebiOrders(vendor_slug: string): Promise<AxiosResponseType<AsoebiOrderType[]> | null> {
    try {
        if (!vendor_slug) {
            return null;
        }
        return await axios.get(`${BASE_URL}/api/v1/vendor-asoebi-orders/${vendor_slug}`);
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function getAsoebiVendorDetails(vendor_email: string): Promise<AxiosResponseType<AsoebiAdminType> | null> {
    try {
        if (!vendor_email) {
            return null;
        }
        return await axios.get(`${BASE_URL}/api/v1/vendor-asoebi-admin-details/${vendor_email}`);
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function getAsoebiVendorOrderForms(vendor_email: string): Promise<AxiosResponseType<AsoebiOrderFormType[]> | null> {
    try {
        if (!vendor_email) {
            return null;
        }
        return await axios.get(`${BASE_URL}/api/v1/asoebi-vendor-order-form/${vendor_email}`);
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function getAsoebiOrderFormsOrders(orderform_id: string): Promise<AxiosResponseType<AsoebiOrderType[]> | null> {
    try {
        if (!orderform_id) {
            return null;
        }
        return await axios.get(`${BASE_URL}/api/v1/order-form-orders/${orderform_id}`);
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function getOrderFormDetails(orderform_id: string): Promise<AxiosResponseType<AsoebiOrderFormType> | null> {
    try {
        if (!orderform_id) {
            return null;
        }
        const orderFormDetails = await axios.get(`${BASE_URL}/api/v1/asoebi-orderform/${orderform_id}`);
        return orderFormDetails;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function getAllVendors(): Promise<AxiosResponseType<Array<VendorType>>> {
    try {
        const getVendorsResponse = await axios.get(`${BASE_URL}/api/v1/get-vendors`);
        return getVendorsResponse;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function getAsoebiOrderDetails(orderform_id: string): Promise<AxiosResponseType<AsoebiOrderFormType | null> | null> {
    try {
        if (!orderform_id) {
            return null;
        }
        return await axios.get(`${BASE_URL}/api/v1/asoebi-orderform/${orderform_id}`);
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function getCustomersSubscriptionDetails(user_id: string): Promise<AxiosResponseType<{ subscriptions: Array<SubscriptionType> }> | null> {
    try {
        if (!user_id) {
            return null;
        }
        return await axios.get(`${BASE_URL}/api/v1/get-subscriptions/${user_id}`);
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}
